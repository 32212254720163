// @ts-ignore
/* eslint-disable */
import request from '../../utils/request';

/** 创建人脸识别 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /esign/face-auth/create */
export async function esignFaceAuthCreateUsingPOST(
  body: API.CreateFaceAuthReq,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultFaceAuthResp>('/esign/face-auth/create', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 获取人脸识别结果 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /esign/face-auth/result */
export async function esignFaceAuthResultUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.esignFaceAuthResultUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultFaceAuthResultResp>('/esign/face-auth/result', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 订单签字 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /esign/order */
export async function esignOrderUsingPOST(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.esignOrderUsingPOSTParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultESignOrderStartResp>('/esign/order', {
    method: 'POST',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 退款订单签字 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /esign/order/refund */
export async function esignOrderRefundUsingPOST(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.esignOrderRefundUsingPOSTParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultESignRefundOrderResp>('/esign/order/refund', {
    method: 'POST',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 查询退款订单签字状态 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /esign/order/refund/status */
export async function esignOrderRefundStatusUsingPOST(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.esignOrderRefundStatusUsingPOSTParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultESignStatusResp>('/esign/order/refund/status', {
    method: 'POST',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 查询订单签字状态 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /esign/order/status */
export async function esignOrderStatusUsingPOST(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.esignOrderStatusUsingPOSTParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultESignStatusResp>('/esign/order/status', {
    method: 'POST',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 查询签字链接结果 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /esign/result */
export async function esignResultUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.esignResultUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultESignStatusResp>('/esign/result', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 生成签字pdf # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /esign/signPdf */
export async function esignSignPdfUsingPOST(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.esignSignPdfUsingPOSTParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultstring>('/esign/signPdf', {
    method: 'POST',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取签字url # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /esign/signUrl */
export async function esignSignUrlUsingPOST(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.esignSignUrlUsingPOSTParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultstring>('/esign/signUrl', {
    method: 'POST',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 策略体验券签字 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /esign/strategy-coupon */
export async function esignStrategyCouponUsingPOST(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.esignStrategyCouponUsingPOSTParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultESignStatusResp>('/esign/strategy-coupon', {
    method: 'POST',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 查询策略体验券签字链接结果 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /esign/strategy-coupon/result */
export async function esignStrategyCouponResultUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.esignStrategyCouponResultUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultESignStatusResp>('/esign/strategy-coupon/result', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 服务体验券签字 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /esign/vip-coupon */
export async function esignVipCouponUsingPOST(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.esignVipCouponUsingPOSTParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultESignStatusResp>('/esign/vip-coupon', {
    method: 'POST',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
