// @ts-ignore
/* eslint-disable */
import request from '../../utils/request';

/** 获取我的优惠券列表 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /coupons/coupons/me */
export async function couponsCouponsMeUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.couponsCouponsMeUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultListCourseCouponResp>('/coupons/coupons/me', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取用户优惠券列表 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /coupons/coupons/users */
export async function couponsCouponsUsersUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.couponsCouponsUsersUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultListCourseCouponResp>('/coupons/coupons/users', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 领取优惠券 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /coupons/gain */
export async function couponsGainUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.couponsGainUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultCouponResp>('/coupons/gain', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 查询活动已领取的优惠券 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /coupons/my/${param0} */
export async function couponsMyPlanNumberUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.couponsMyPlanNumberUsingGETParams,
  options?: { [key: string]: any },
) {
  const { planNumber: param0, ...queryParams } = params;
  return request<API.BaseResultListCouponResp>(`/coupons/my/${param0}`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}
