import React from 'react';

export default {
  routes: [
    {
      exact: true,
      path: '/redirect',
      isDynamic: true,
      element: React.lazy(
        () => import( '@/pages/Redirect'),
      ),
    },
    {
      exact: true,
      path: '/authorization',
      isDynamic: true,
      element: React.lazy(
          () => import( '@/pages/Authorization'),
      ),
    },
    {
      path:'/',
      element:React.lazy(
          () => import( '@/pages/home'),
      ),

    },
    {
      path: 'pc-k/*',
      element:React.lazy(
          () => import( '@/pages/pc-k'),
      ),
      children: [
        {
          path:'360skf-a01',
          element:React.lazy(
              () => import( '@/pages/pc-k/360skf-a01'),
          ),
        },
      ]
    },
    {
      path: 'pc-h5/*',
      element:React.lazy(
          () => import( '@/pages/pc-h5'),
      ),
      children: [
        {
          path:'360pc-C02',
          element:React.lazy(
              () => import( '@/pages/pc-h5/360pc-C02'),
          ),
        },
        {
          path:'360pc-C01',
          element:React.lazy(
              () => import( '@/pages/pc-h5/360pc-C01'),
          ),
        },
        {
          path:'360pc-a01',
          element:React.lazy(
              () => import( '@/pages/pc-h5/360pc-a01'),
          ),
        },
        {
          path:'360pc-a02',
          element:React.lazy(
              () => import( '@/pages/pc-h5/360pc-a02'),
          ),
        }
      ]
    },
    {
      path:'bds-h5/*',
      element:React.lazy(
          () => import( '@/pages/bds-h5'),
      ),
      children:[
        {
          path:'bdpc-bp02',
          element:React.lazy(
              () => import( '@/pages/bds-h5/bdpc-bp02'),
          ),
        },
        {
          path:'bdpc-bp01',
          element:React.lazy(
              () => import( '@/pages/bds-h5/bdpc-bp01'),
          ),
        },
        {
          path:'bdpc-B01',
          element:React.lazy(
              () => import( '@/pages/bds-h5/bdpc-B01'),
          ),
        },
        {
          path:'bdpc-02',
          element:React.lazy(
              () => import( '@/pages/bds-h5/bdpc-02'),
          ),
        },
        {
          path:'bdpc-03',
          element:React.lazy(
              () => import( '@/pages/bds-h5/bdpc-03'),
          ),
        },
        {
          path:'bdpc-04',
          element:React.lazy(
              () => import( '@/pages/bds-h5/bdpc-04'),
          ),
        },
      ]
    },
    {
      path:'bds-k/*',
      element:React.lazy(
          () => import( '@/pages/bds-k'),
      ),
      children:[
        {
          path:'bdskf-01',
          element:React.lazy(
              () => import( '@/pages/bds-k/bdskf-01'),
          ),
        }
      ]
    },
    {
      path:'h5-p-k/*',
      element:React.lazy(
          () => import( '@/pages/h5-p-k'),
      ),
      children:[
        {
          path:'ttkf-a01',
          element:React.lazy(
              () => import( '@/pages/h5-p-k/ttkf-a01'),
          ),
        },
      ]
    },
    {
      path:'pc-b/*',
      element:React.lazy(
          () => import( '@/pages/pc-b'),
      ),
      children:[
        {
          path:'bdkf-B20',
          element:React.lazy(
              () => import( '@/pages/pc-b/bdkf-B20'),
          ),
        },
      ]
    },
    {
      path:'h-ms/*',
      element:React.lazy(
          ()=> import('@/pages/h-ms'),
      ),
      children: [
        {
          path:'ttm-a09',
          element:React.lazy(
            ()=> import('@/pages/h-ms/ttm-a09'),
          )
        },
        {
          path:'ttm-a08',
          element:React.lazy(
            ()=> import('@/pages/h-ms/ttm-a08'),
          )
        },
        {
          path:'ttm-a07',
          element:React.lazy(
              ()=> import('@/pages/h-ms/ttm-a07'),
          )
        },
        {
          path:'ttm-a06',
          element:React.lazy(
              ()=> import('@/pages/h-ms/ttm-a06'),
          )
        },
        {
          path:'xhsm-a01',
          element:React.lazy(
              ()=> import('@/pages/h-ms/xhsm-a01'),
          )
        },
        {
          path:'ttm-a05',
          element:React.lazy(
              ()=> import('@/pages/h-ms/ttm-a05'),
          )
        },
        {
          path:'ttm-a04',
          element:React.lazy(
              ()=> import('@/pages/h-ms/ttm-a04'),
          )
        },
        {
          path:'ttm-a03',
          element:React.lazy(
              ()=> import('@/pages/h-ms/ttm-a03'),
          )
        },
        {
          path:'ttm-a02',
          element:React.lazy(
              ()=> import('@/pages/h-ms/ttm-a02'),
          )
        },
        {
          path:'ttm-a01',
          element:React.lazy(
              ()=> import('@/pages/h-ms/ttm-a01'),
          )
        },
      ]
    },
    {
      path:'h-ms-pay/*',
      element:React.lazy(
          ()=> import('@/pages/h-ms-pay'),
      ),
      children: [
        {
          path:'ttm-a24',
          element:React.lazy(
              ()=> import('@/pages/h-ms-pay/ttm-a24'),
          )
        },
        {
          path:'ttm-a23',
          element:React.lazy(
              ()=> import('@/pages/h-ms-pay/ttm-a23'),
          )
        },
        {
          path:'ttm-a22',
          element:React.lazy(
            ()=> import('@/pages/h-ms-pay/ttm-a22'),
          )
        },
        {
          path:'ttm-a21',
          element:React.lazy(
            ()=> import('@/pages/h-ms-pay/ttm-a21'),
          )
        },
        {
          path:'ttm-a20',
          element:React.lazy(
            ()=> import('@/pages/h-ms-pay/ttm-a20'),
          )
        },
        {
          path:'ttm-a19',
          element:React.lazy(
            ()=> import('@/pages/h-ms-pay/ttm-a19'),
          )
        },
        {
          path:'ttm-a18',
          element:React.lazy(
            ()=> import('@/pages/h-ms-pay/ttm-a18'),
          )
        },
        {
          path:'ttm-a17',
          element:React.lazy(
              ()=> import('@/pages/h-ms-pay/ttm-a17'),
          )
        },
        {
          path:'ttm-a16',
          element:React.lazy(
              ()=> import('@/pages/h-ms-pay/ttm-a16'),
          )
        },
        {
          path:'gdtm-g10',
          element:React.lazy(
              ()=> import('@/pages/h-ms-pay/gdtm-g10'),
          )
        },
        {
          path:'gdtm-g09',
          element:React.lazy(
              ()=> import('@/pages/h-ms-pay/gdtm-g09'),
          )
        },
        {
          path:'gdtm-g08',
          element:React.lazy(
              ()=> import('@/pages/h-ms-pay/gdtm-g08'),
          )
        },
        {
          path:'gdtm-g07',
          element:React.lazy(
              ()=> import('@/pages/h-ms-pay/gdtm-g07'),
          )
        },
        {
          path:'ttm-a15',
          element:React.lazy(
              ()=> import('@/pages/h-ms-pay/ttm-a15'),
          )
        },
        {
          path:'ttm-a14',
          element:React.lazy(
              ()=> import('@/pages/h-ms-pay/ttm-a14'),
          )
        },
        {
          path:'ttm-a13',
          element:React.lazy(
              ()=> import('@/pages/h-ms-pay/ttm-a13'),
          )
        },
        {
          path:'gdtm-g06',
          element:React.lazy(
              ()=> import('@/pages/h-ms-pay/gdtm-g06'),
          )
        },
        {
          path:'ttm-a12',
          element:React.lazy(
              ()=> import('@/pages/h-ms-pay/ttm-a12'),
          )
        },
        {
          path:'gdtm-g05',
          element:React.lazy(
              ()=> import('@/pages/h-ms-pay/gdtm-g05'),
          )
        },
        {
          path:'ttm-a11',
          element:React.lazy(
              ()=> import('@/pages/h-ms-pay/ttm-a11'),
          )
        },
        {
          path:'ttm-a05',
          element:React.lazy(
              ()=> import('@/pages/h-ms-pay/ttm-a05'),
          )
        },
        {
          path:'ttm-a04',
          element:React.lazy(
              ()=> import('@/pages/h-ms-pay/ttm-a04'),
          )
        },
        {
          path:'gdtm-g01',
          element:React.lazy(
              ()=> import('@/pages/h-ms-pay/gdtm-g01'),
          )
        },
        {
          path:'gdtm-g02',
          element:React.lazy(
              ()=> import('@/pages/h-ms-pay/gdtm-g02'),
          )
        },
        {
          path:'gdtm-g03',
          element:React.lazy(
              ()=> import('@/pages/h-ms-pay/gdtm-g03'),
          )
        },
        {
          path:'gdtm-g04',
          element:React.lazy(
              ()=> import('@/pages/h-ms-pay/gdtm-g04'),
          )
        },
        {
          path:'ttm-a03',
          element:React.lazy(
              ()=> import('@/pages/h-ms-pay/ttm-a03'),
          )
        },
        {
          path:'ttm-g02',
          element:React.lazy(
              ()=> import('@/pages/h-ms-pay/ttm-g02'),
          )
        },
        {
          path:'ttm-g01',
          element:React.lazy(
              ()=> import('@/pages/h-ms-pay/ttm-g01'),
          )
        },
        {
          path:'ttm-a02',
          element:React.lazy(
              ()=> import('@/pages/h-ms-pay/ttm-a02'),
          )
        },
        {
          path:'ttm-a01',
          element:React.lazy(
              ()=> import('@/pages/h-ms-pay/ttm-a01'),
          )
        },
        {
          path:'ttm-a06',
          element:React.lazy(
              ()=> import('@/pages/h-ms-pay/ttm-a06'),
          )
        },
        {
          path:'ttm-a07',
          element:React.lazy(
              ()=> import('@/pages/h-ms-pay/ttm-a07'),
          )
        },
        {
          path:'ttm-a08',
          element:React.lazy(
              ()=> import('@/pages/h-ms-pay/ttm-a08'),
          )
        },
        {
          path:'ttm-a09',
          element:React.lazy(
              ()=> import('@/pages/h-ms-pay/ttm-a09'),
          )
        },
        {
          path:'ttm-a10',
          element:React.lazy(
              ()=> import('@/pages/h-ms-pay/ttm-a10'),
          )
        },
      ]
    },
    {
      path:'h-m/*',
      element:React.lazy(
          ()=> import('@/pages/h-m'),
      ),
      children: [
        {
          path:'ttm-a06',
          element:React.lazy(
              ()=> import('@/pages/h-m/ttm-a06'),
          )
        },
        {
          path:'ttm-a05',
          element:React.lazy(
              ()=> import('@/pages/h-m/ttm-a05'),
          )
        },
        {
          path:'ttm-a04',
          element:React.lazy(
              ()=> import('@/pages/h-m/ttm-a04'),
          )
        },
        {
          path:'ttm-a03',
          element:React.lazy(
              ()=> import('@/pages/h-m/ttm-a03'),
          )
        },
        {
          path:'ttm-a02',
          element:React.lazy(
              ()=> import('@/pages/h-m/ttm-a02'),
          )
        },
        {
          path:'ttm-a01',
          element:React.lazy(
              ()=> import('@/pages/h-m/ttm-a01'),
          )
        },
        {
          path:'gdtm-a01',
          element:React.lazy(
              ()=> import('@/pages/h-m/gdtm-a01'),
          )
        }
      ]
    },
    {
      path:'h-m-pay/*',
      element:React.lazy(
          ()=> import('@/pages/h-m-pay'),
      ),
      children: [
        {
          path:'ttm-a02',
          element:React.lazy(
              ()=> import('@/pages/h-m-pay/ttm-a02'),
          )
        },
        {
          path:'ttm-a01',
          element:React.lazy(
              ()=> import('@/pages/h-m-pay/ttm-a01'),
          )
        },
        {
          path:'gdtm-a01',
          element:React.lazy(
              ()=> import('@/pages/h-m-pay/gdtm-a01'),
          )
        }
      ]
    },
    {
      path:'mina-h5-w/*',
      element:React.lazy(
          ()=> import('@/pages/mina-h5-w'),
      ),
      children: [
        {
          path:'ttm-a02',
          element:React.lazy(
              ()=> import('@/pages/mina-h5-w/ttm-a02'),
          )
        },
        {
          path:'ttm-a01',
          element:React.lazy(
              ()=> import('@/pages/mina-h5-w/ttm-a01'),
          )
        },
        {
          path:'xhsm-a01',
          element:React.lazy(
            ()=> import('@/pages/mina-h5-w/xhsm-a01'),
          )
        },
      ]
    },
    {
      path:'h-k/*',
      element:React.lazy(
          () => import( '@/pages/h-k'),
      ),
      children:[
        {
          path:'bdkf-B36',
          element:React.lazy(
              () => import( '@/pages/h-k/bdkf-B36'),
          ),
        },
        {
          path:'bdkf-B35',
          element:React.lazy(
              () => import( '@/pages/h-k/bdkf-B35'),
          ),
        },
        {
          path:'ttkf-D137',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D137'),
          ),
        },
        {
          path:'ttkf-D136',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D136'),
          ),
        },
        {
          path:'ttkf-D135',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D135'),
          ),
        },
        {
          path:'ttkf-D134',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D134'),
          ),
        },
        {
          path:'ttkf-D133',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D133'),
          ),
        },
        {
          path:'ttkf-D132',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D132'),
          ),
        },
        {
          path:'ttkf-D131',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D131'),
          ),
        },
        {
          path:'ttkf-D130',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D130'),
          ),
        },
        {
          path:'ttkf-D128',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D128'),
          ),
        },
        {
          path:'ttkf-D127',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D127'),
          ),
        },
        {
          path:'gdtkf-A38',
          element:React.lazy(
              () => import( '@/pages/h-k/gdtkf-A38'),
          ),
        },
        {
          path:'gdtkf-A37',
          element:React.lazy(
              () => import( '@/pages/h-k/gdtkf-A37'),
          ),
        },
        {
          path:'gdtkf-A35',
          element:React.lazy(
              () => import( '@/pages/h-k/gdtkf-A35'),
          ),
        },
        {
          path:'gdtkf-A34',
          element:React.lazy(
              () => import( '@/pages/h-k/gdtkf-A34'),
          ),
        },
        {
          path:'gdtkf-A33',
          element:React.lazy(
              () => import( '@/pages/h-k/gdtkf-A33'),
          ),
        },
        {
          path:'gdtkf-A31',
          element:React.lazy(
              () => import( '@/pages/h-k/gdtkf-A31'),
          ),
        },
        {
          path:'bdkf-B34',
          element:React.lazy(
              () => import( '@/pages/h-k/bdkf-B34'),
          ),
        },
        {
          path:'bdkf-B33',
          element:React.lazy(
              () => import( '@/pages/h-k/bdkf-B33'),
          ),
        },
        {
          path:'bdkf-B32',
          element:React.lazy(
              () => import( '@/pages/h-k/bdkf-B32'),
          ),
        },
        {
          path:'bdkf-B31',
          element:React.lazy(
              () => import( '@/pages/h-k/bdkf-B31'),
          ),
        },
        {
          path:'bdkf-B30',
          element:React.lazy(
              () => import( '@/pages/h-k/bdkf-B30'),
          ),
        },
        {
          path:'bdkf-B29',
          element:React.lazy(
              () => import( '@/pages/h-k/bdkf-B29'),
          ),
        },
        {
          path:'bdkf-B28',
          element:React.lazy(
              () => import( '@/pages/h-k/bdkf-B28'),
          ),
        },
        {
          path:'bdkf-B27',
          element:React.lazy(
              () => import( '@/pages/h-k/bdkf-B27'),
          ),
        },
        {
          path:'bdkf-B26',
          element:React.lazy(
              () => import( '@/pages/h-k/bdkf-B26'),
          ),
        },
        {
          path:'bdkf-B25',
          element:React.lazy(
              () => import( '@/pages/h-k/bdkf-B25'),
          ),
        },
        {
          path:'bdkf-B24',
          element:React.lazy(
              () => import( '@/pages/h-k/bdkf-B24'),
          ),
        },
        {
          path:'bdkf-B23',
          element:React.lazy(
              () => import( '@/pages/h-k/bdkf-B23'),
          ),
        },
        {
          path:'ttkf-D124',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D124'),
          ),
        },
        {
          path:'xhskf-a03',
          element:React.lazy(
              () => import( '@/pages/h-k/xhskf-a03'),
          ),
        },
        {
          path:'xhskf-a04',
          element:React.lazy(
              () => import( '@/pages/h-k/xhskf-a04'),
          ),
        },
        {
          path:'xhskf-a05',
          element:React.lazy(
              () => import( '@/pages/h-k/xhskf-a05'),
          ),
        },
        {
          path:'ttkf-D123',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D123'),
          ),
        },
        {
          path:'bdkf-B22',
          element:React.lazy(
              () => import( '@/pages/h-k/bdkf-B22'),
          ),
        },
        {
          path:'bdkf-B19',
          element:React.lazy(
              () => import( '@/pages/h-k/bdkf-B19'),
          ),
        },
        {
          path:'bdkf-B18',
          element:React.lazy(
              () => import( '@/pages/h-k/bdkf-B18'),
          ),
        },
        {
          path:'bdkf-B17',
          element:React.lazy(
              () => import( '@/pages/h-k/bdkf-B17'),
          ),
        },
        {
          path:'bdkf-B16',
          element:React.lazy(
              () => import( '@/pages/h-k/bdkf-B16'),
          ),
        },
        {
          path:'bdkf-B15',
          element:React.lazy(
              () => import( '@/pages/h-k/bdkf-B15'),
          ),
        },
        {
          path:'ttkf-D122',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D122'),
          ),
        },
        {
          path:'bdkf-B14',
          element:React.lazy(
              () => import( '@/pages/h-k/bdkf-B14'),
          ),
        },
        {
          path:'gdtkf-A36',
          element:React.lazy(
              () => import( '@/pages/h-k/gdtkf-A36'),
          ),
        },
        {
          path:'gdtkf-A30',
          element:React.lazy(
              () => import( '@/pages/h-k/gdtkf-A30'),
          ),
        },
        {
          path:'gdtkf-A29',
          element:React.lazy(
              () => import( '@/pages/h-k/gdtkf-A29'),
          ),
        },
        {
          path:'gdtkf-A28',
          element:React.lazy(
              () => import( '@/pages/h-k/gdtkf-A28'),
          ),
        },
        {
          path:'ttkf-D121',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D121'),
          ),
        },
        {
          path:'hwkf-H03',
          element:React.lazy(
              () => import( '@/pages/h-k/hwkf-H03'),
          ),
        },
        {
          path:'hwkf-H04',
          element:React.lazy(
              () => import( '@/pages/h-k/hwkf-H04'),
          ),
        },
        {
          path:'hwkf-H05',
          element:React.lazy(
              () => import( '@/pages/h-k/hwkf-H05'),
          ),
        },
        {
          path:'hwkf-H06',
          element:React.lazy(
              () => import( '@/pages/h-k/hwkf-H06'),
          ),
        },
        {
          path:'ttkf-D120',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D120'),
          ),
        },
        {
          path:'uckf-B05',
          element:React.lazy(
              () => import( '@/pages/h-k/uckf-B05'),
          ),
        },
        {
          path:'uckf-B04',
          element:React.lazy(
              () => import( '@/pages/h-k/uckf-B04'),
          ),
        },
        {
          path:'bdkf-B12',
          element:React.lazy(
              () => import( '@/pages/h-k/bdkf-B12'),
          ),
        },
        {
          path:'bdkf-B13',
          element:React.lazy(
              () => import( '@/pages/h-k/bdkf-B13'),
          ),
        },
        {
          path:'gdtkf-A27',
          element:React.lazy(
              () => import( '@/pages/h-k/gdtkf-A27'),
          ),
        },
        {
          path:'ttkf-D119',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D119'),
          ),
        },
        {
          path:'ttkf-D118',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D118'),
          ),
        },
        {
          path:'kskf-L19',
          element:React.lazy(
              () => import( '@/pages/h-k/kskf-L19'),
          ),
        },
        {
          path:'kskf-L18',
          element:React.lazy(
              () => import( '@/pages/h-k/kskf-L18'),
          ),
        },
        {
          path:'ttkf-D117',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D117'),
          ),
        },
        {
          path:'ttkf-D116',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D116'),
          ),
        },
        {
          path:'ttkf-D115',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D115'),
          ),
        },
        {
          path:'gdtkf-A26',
          element:React.lazy(
              () => import( '@/pages/h-k/gdtkf-A26'),
          ),
        },
        {
          path:'bdkf-B11',
          element:React.lazy(
              () => import( '@/pages/h-k/bdkf-B11'),
          ),
        },
        {
          path:'bdkf-B10',
          element:React.lazy(
              () => import( '@/pages/h-k/bdkf-B10'),
          ),
        },
        {
          path:'gdtkf-A25',
          element:React.lazy(
              () => import( '@/pages/h-k/gdtkf-A25'),
          ),
        },
        {
          path:'ttkf-D114',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D114'),
          ),
        },
        {
          path:'xhskf-A01',
          element:React.lazy(
              () => import( '@/pages/h-k/xhskf-A01'),
          ),
        },
        {
          path:'xhskf-A02',
          element:React.lazy(
              () => import( '@/pages/h-k/xhskf-A02'),
          ),
        },
        {
          path:'bdkf-B09',
          element:React.lazy(
              () => import( '@/pages/h-k/bdkf-B09'),
          ),
        },
        {
          path:'gdtkf-A24',
          element:React.lazy(
              () => import( '@/pages/h-k/gdtkf-A24'),
          ),
        },
        {
          path:'ttkf-D113',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D113'),
          ),
        },
        {
          path:'ttkf-D112',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D112'),
          ),
        },
        {
          path:'ttkf-D111',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D111'),
          ),
        },
        {
          path:'gdtkf-A23',
          element:React.lazy(
              () => import( '@/pages/h-k/gdtkf-A23'),
          ),
        },
        {
          path:'bdkf-B08',
          element:React.lazy(
              () => import( '@/pages/h-k/bdkf-B08'),
          ),
        },
        {
          path:'ttkf-D110',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D110'),
          ),
        },
        {
          path:'opkf-C03',
          element:React.lazy(
              () => import( '@/pages/h-k/opkf-C03'),
          ),
        },
        {
          path:'ttkf-D109',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D109'),
          ),
        },
        {
          path:'ttkf-D108',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D108'),
          ),
        },
        {
          path:'bdkf-B07',
          element:React.lazy(
              () => import( '@/pages/h-k/bdkf-B07'),
          ),
        },
        {
          path:'ttkf-D107',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D107'),
          ),
        },
        {
          path:'ttkf-D106',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D106'),
          ),
        },
        {
          path:'ttkf-D105',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D105'),
          ),
        },
        {
          path:'ttkf-D101',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D101'),
          ),
        },
        {
          path:'ttkf-D104',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D104'),
          ),
        },
        {
          path:'ttkf-D103',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D103'),
          ),
        },
        {
          path:'gdtkf-A22',
          element:React.lazy(
              () => import( '@/pages/h-k/gdtkf-A22'),
          ),
        },
        {
          path:'bdkf-B06',
          element:React.lazy(
              () => import( '@/pages/h-k/bdkf-B06'),
          ),
        },
        {
          path:'ttkf-D102',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D102'),
          ),
        },
        {
          path:'ttkf-D101',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D101'),
          ),
        },
        {
          path:'vvkf-A06',
          element:React.lazy(
              () => import( '@/pages/h-k/vvkf-A06'),
          ),
        },
        {
          path:'vvkf-A05',
          element:React.lazy(
              () => import( '@/pages/h-k/vvkf-A05'),
          ),
        },
        {
          path:'vvkf-A04',
          element:React.lazy(
              () => import( '@/pages/h-k/vvkf-A04'),
          ),
        },
        {
          path:'opkf-C02',
          element:React.lazy(
              () => import( '@/pages/h-k/opkf-C02'),
          ),
        },
        {
          path:'gdtkf-A21',
          element:React.lazy(
              () => import( '@/pages/h-k/gdtkf-A21'),
          ),
        },
        {
          path:'bdkf-B04',
          element:React.lazy(
              () => import( '@/pages/h-k/bdkf-B04'),
          ),
        },
        {
          path:'bdkf-B05',
          element:React.lazy(
              () => import( '@/pages/h-k/bdkf-B05'),
          ),
        },
        {
          path:'bdkf-B03',
          element:React.lazy(
              () => import( '@/pages/h-k/bdkf-B03'),
          ),
        },
        {
          path:'bdkf-B02',
          element:React.lazy(
              () => import( '@/pages/h-k/bdkf-B02'),
          ),
        },
        {
          path:'bdkf-B01',
          element:React.lazy(
              () => import( '@/pages/h-k/bdkf-B01'),
          ),
        },
        {
          path:'ttkf-D100',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D100'),
          ),
        },
          {
              path:'ttkf-D99',
              element:React.lazy(
                  () => import( '@/pages/h-k/ttkf-D99'),
              ),
          },
        {
          path:'bdkf-A01',
          element:React.lazy(
              () => import( '@/pages/h-k/bdkf-A01'),
          ),
        },
        {
          path:'ttkf-D98',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D98'),
          ),
        },
        {
          path:'ttkf-D97',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D97'),
          ),
        },
        {
          path:'hwkf-H02',
          element:React.lazy(
              () => import( '@/pages/h-k/hwkf-H02'),
          ),
        },
          {
              path:'ttkf-D96',
              element:React.lazy(
                  () => import( '@/pages/h-k/ttkf-D96'),
              ),
          },
        {
          path:'ttkf-D95',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D95'),
          ),
        },
        {
          path:'gdtkf-A20',
          element:React.lazy(
              () => import( '@/pages/h-k/gdtkf-A20'),
          ),
        },
        {
          path:'gdtkf-A19',
          element:React.lazy(
              () => import( '@/pages/h-k/gdtkf-A19'),
          ),
        },
        {
          path:'ttkf-D94',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D94'),
          ),
        },
        {
          path:'ttkf-D93',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D93'),
          ),
        },
        {
          path:'ttkf-D92',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D92'),
          ),
        },
        {
          path:'ttkf-D91',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D91'),
          ),
        },
        {
          path:'gdtkf-A18',
          element:React.lazy(
              () => import( '@/pages/h-k/gdtkf-A18'),
          ),
        },
        {
          path:'ttkf-D90',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D90'),
          ),
        },
        {
          path:'hwkf-H01',
          element:React.lazy(
              () => import( '@/pages/h-k/hwkf-H01'),
          ),
        },
        {
          path:'ttkf-D89',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D89'),
          ),
        },
        {
          path:'ttkf-D88',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D88'),
          ),
        },
        {
          path:'gdtkf-A17',
          element:React.lazy(
              () => import( '@/pages/h-k/gdtkf-A17'),
          ),
        },
        {
          path:'ttkf-D87',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D87'),
          ),
        },
        {
          path:'ttkf-D86',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D86'),
          ),
        },
        {
          path:'opkf-C01',
          element:React.lazy(
              () => import( '@/pages/h-k/opkf-C01'),
          ),
        },
        {
          path:'ttkf-D85',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D85'),
          ),
        },
        {
          path:'ttkf-D84',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D84'),
          ),
        },
        {
          path:'opkf-A01',
          element:React.lazy(
              () => import( '@/pages/h-k/opkf-A01'),
          ),
        },
        {
          path:'ttkf-D83',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D83'),
          ),
        },
        {
          path:'ttkf-D82',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D82'),
          ),
        },
        {
          path:'ttkf-D81',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D81'),
          ),
        },
        {
          path:'ttkf-D80',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D80'),
          ),
        },
        {
          path:'gdtkf-A15',
          element:React.lazy(
              () => import( '@/pages/h-k/gdtkf-A15'),
          ),
        },
        {
          path:'vvkf-A03',
          element:React.lazy(
              () => import( '@/pages/h-k/vvkf-A03'),
          ),
        },
        {
          path:'gdtkf-A14',
          element:React.lazy(
              () => import( '@/pages/h-k/gdtkf-A14'),
          ),
        },
        {
          path:'ttkf-D79',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D79'),
          ),
        },
        {
          path:'ttkf-D78',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D78'),
          ),
        },
        {
          path:'ttkf-D77',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D77'),
          ),
        },
        {
          path:'ttkf-D76',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D76'),
          ),
        },
        {
          path:'ttkf-D75',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D75'),
          ),
        },
          {
              path:'gdtkf-A13',
              element:React.lazy(
                  () => import( '@/pages/h-k/gdtkf-A13'),
              ),
          },
          {
              path:'ttkf-D74',
              element:React.lazy(
                  () => import( '@/pages/h-k/ttkf-D74'),
              ),
          },
          {
              path:'ttkf-D73',
              element:React.lazy(
                  () => import( '@/pages/h-k/ttkf-D73'),
              ),
          },
        {
          path:'vvkf-A02',
          element:React.lazy(
              () => import( '@/pages/h-k/vvkf-A02'),
          ),
        },
        {
          path:'ttkf-D72',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D72'),
          ),
        },
        {
          path:'ttkf-D71',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D71'),
          ),
        },
        {
          path:'ttkf-D70',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D70'),
          ),
        },
        {
          path:'ttkf-D69',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D69'),
          ),
        },
        {
          path:'ttkf-D68',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D68'),
          ),
        },
        {
          path:'ttkf-D67',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D67'),
          ),
        },
        {
          path:'gdtkf-A12',
          element:React.lazy(
              () => import( '@/pages/h-k/gdtkf-A12'),
          ),
        },
        {
          path:'gdtkf-A11',
          element:React.lazy(
              () => import( '@/pages/h-k/gdtkf-A11'),
          ),
        },
        {
          path:'hwkf-A01',
          element:React.lazy(
              () => import( '@/pages/h-k/hwkf-A01'),
          ),
        },
        {
          path:'ttkf-D66',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D66'),
          ),
        },
        {
          path:'ttkf-D65',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D65'),
          ),
        },
        {
          path:'kskf-L17',
          element:React.lazy(
              () => import( '@/pages/h-k/kskf-L17'),
          ),
        },
        {
          path:'ttkf-D64',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D64'),
          ),
        },
        {
          path:'ttkf-D63',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D63'),
          ),
        },
        {
          path:'ttkf-D62',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D62'),
          ),
        },
        {
          path:'ttkf-D61',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D61'),
          ),
        },
        {
          path:'ttkf-D60',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D60'),
          ),
        },
        {
          path:'ttkf-D59',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D59'),
          ),
        },
        {
          path:'gdtkf-A10',
          element:React.lazy(
              () => import( '@/pages/h-k/gdtkf-A10'),
          ),
        },
        {
          path:'uckf-B03',
          element:React.lazy(
              () => import( '@/pages/h-k/uckf-B03'),
          ),
        },
        {
          path:'uckf-B02',
          element:React.lazy(
              () => import( '@/pages/h-k/uckf-B02'),
          ),
        },
        {
          path:'ttkf-D58',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D58'),
          ),
        },
        {
          path:'ttkf-D57',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D57'),
          ),
        },
        {
          path:'kskf-L16',
          element:React.lazy(
              () => import( '@/pages/h-k/kskf-L16'),
          ),
        },
        {
          path:'kskf-L15',
          element:React.lazy(
              () => import( '@/pages/h-k/kskf-L15'),
          ),
        },
        {
          path:'ttkf-D56',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D56'),
          ),
        },
        {
          path:'ttkf-D55',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D55'),
          ),
        },
        {
          path:'ttkf-D54',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D54'),
          ),
        },
        {
          path:'ttkf-D53',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D53'),
          ),
        },
        {
          path:'ttkf-D52',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D52'),
          ),
        },
        {
          path:'ttkf-D51',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D51'),
          ),
        },
        {
          path:'ttkf-D50',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D50'),
          ),
        },
        {
          path:'ttkf-D49',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D49'),
          ),
        },
        {
          path:'ttkf-D46',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D46'),
          ),
        },
        {
          path:'ttkf-D48',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D48'),
          ),
        },
        {
          path:'ttkf-D47',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D47'),
          ),
        },
        {
          path:'gdtkf-A09',
          element:React.lazy(
              () => import( '@/pages/h-k/gdtkf-A09'),
          ),
        },
        {
          path:'ttkf-D45',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D45'),
          ),
        },
        {
          path:'ttkf-D44',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D44'),
          ),
        },
        {
          path:'kskf-L14',
          element:React.lazy(
              () => import( '@/pages/h-k/kskf-L14'),
          ),
        },
        {
          path:'ttkf-D43',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D43'),
          ),
        },
        {
          path:'gdtkf-A08',
          element:React.lazy(
              () => import( '@/pages/h-k/gdtkf-A08'),
          ),
        },
        {
          path:'ttkf-D42',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D42'),
          ),
        },
        {
          path:'ttkf-D41',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D41'),
          ),
        },
        {
          path:'gdtkf-A07',
          element:React.lazy(
              () => import( '@/pages/h-k/gdtkf-A07'),
          ),
        },
        {
          path:'ttkf-D40',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D40'),
          ),
        },
        {
          path:'ttkf-D39',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D39'),
          ),
        },
        {
          path:'ttkf-D38',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D38'),
          ),
        },
        {
          path:'kskf-L13',
          element:React.lazy(
              () => import( '@/pages/h-k/kskf-L13'),
          ),
        },
        {
          path:'ttkf-D36',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D36'),
          ),
        },
        {
          path:'ttkf-D37',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D37'),
          ),
        },
        {
          path:'ttkf-D28',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D28'),
          ),
        },
        {
          path:'ttkf-D07',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D07'),
          ),
        },
        {
          path:'ttkf-D08',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D08'),
          ),
        },
        {
          path:'ttkf-D09',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D09'),
          ),
        },
        {
          path:'ttkf-D10',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D10'),
          ),
        },
        {
          path:'ttkf-D11',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D11'),
          ),
        },
        {
          path:'ttkf-D12',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D12'),
          ),
        },
        {
          path:'ttkf-D13',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D13'),
          ),
        },
        {
          path:'ttkf-D14',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D14'),
          ),
        },
        {
          path:'ttkf-D15',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D15'),
          ),
        },
        {
          path:'ttkf-D16',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D16'),
          ),
        },
        {
          path:'ttkf-D17',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D17'),
          ),
        },
        {
          path:'ttkf-D18',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D18'),
          ),
        },
        {
          path:'ttkf-D19',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D19'),
          ),
        },
        {
          path:'ttkf-D20',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D20'),
          ),
        },
        {
          path:'kskf-L02',
          element:React.lazy(
              () => import( '@/pages/h-k/kskf-L02'),
          ),
        },
        {
          path:'kskf-L03',
          element:React.lazy(
              () => import( '@/pages/h-k/kskf-L03'),
          ),
        },
        {
          path:'kskf-L04',
          element:React.lazy(
              () => import( '@/pages/h-k/kskf-L04'),
          ),
        },
        {
          path:'kskf-L05',
          element:React.lazy(
              () => import( '@/pages/h-k/kskf-L05'),
          ),
        },
        {
          path:'kskf-L06',
          element:React.lazy(
              () => import( '@/pages/h-k/kskf-L06'),
          ),
        },
        {
          path:'kskf-L07',
          element:React.lazy(
              () => import( '@/pages/h-k/kskf-L07'),
          ),
        },
        {
          path:'kskf-L08',
          element:React.lazy(
              () => import( '@/pages/h-k/kskf-L08'),
          ),
        },
        {
          path:'kskf-L09',
          element:React.lazy(
              () => import( '@/pages/h-k/kskf-L09'),
          ),
        },
        {
          path:'kskf-L10',
          element:React.lazy(
              () => import( '@/pages/h-k/kskf-L10'),
          ),
        },
        {
          path:'kskf-L11',
          element:React.lazy(
              () => import( '@/pages/h-k/kskf-L11'),
          ),
        },
        {
          path:'kskf-L12',
          element:React.lazy(
              () => import( '@/pages/h-k/kskf-L12'),
          ),
        },
        {
          path:'gdtkf-A01',
          element:React.lazy(
              () => import( '@/pages/h-k/gdtkf-A01'),
          ),
        },
        {
          path:'gdtkf-A02',
          element:React.lazy(
              () => import( '@/pages/h-k/gdtkf-A02'),
          ),
        },
        {
          path:'gdtkf-A03',
          element:React.lazy(
              () => import( '@/pages/h-k/gdtkf-A03'),
          ),
        },
        {
          path:'ttkf-Dp03',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-Dp03'),
          ),
        },
        {
          path:'ttkf-Dp04',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-Dp04'),
          ),
        },
        {
          path:'gdtkf-Ap01',
          element:React.lazy(
              () => import( '@/pages/h-k/gdtkf-Ap01'),
          ),
        },
        {
          path:'gdtkf-Ap02',
          element:React.lazy(
              () => import( '@/pages/h-k/gdtkf-Ap02'),
          ),
        },
        {
          path:'ttkf-Dp20',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-Dp20'),
          ),
        },
        {
          path:'ttkf-D22',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D22'),
          ),
        },
        {
          path:'ttkf-D21',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D21'),
          ),
        },
        {
          path:'ttkf-D23',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D23'),
          ),
        },
        {
          path:'gdtkf-A04',
          element:React.lazy(
              () => import( '@/pages/h-k/gdtkf-A04'),
          ),
        },
        {
          path:'ttkf-D24',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D24'),
          ),
        },
        {
          path:'ttkf-D25',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D25'),
          ),
        },
        {
          path:'ttkf-D26',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D26'),
          ),
        },
        {
          path:'ttkf-D27',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D27'),
          ),
        },
        {
          path:'ttkf-D29',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D29'),
          ),
        },
        {
          path:'ttkf-D30',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D30'),
          ),
        },
        {
          path:'gdtkf-A05',
          element:React.lazy(
              () => import( '@/pages/h-k/gdtkf-A05'),
          ),
        },
        {
          path:'ttkf-D31',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D31'),
          ),
        },
        {
          path:'gdtkf-A06',
          element:React.lazy(
              () => import( '@/pages/h-k/gdtkf-A06'),
          ),
        },
        {
          path:'ttkf-D32',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D32'),
          ),
        },
        {
          path:'ttkf-D33',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D33'),
          ),
        },
        {
          path:'ttkf-D34',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D34'),
          ),
        },
        {
          path:'ttkf-D35',
          element:React.lazy(
              () => import( '@/pages/h-k/ttkf-D35'),
          ),
        },
        {
          path:'uckf-B01',
          element:React.lazy(
              () => import( '@/pages/h-k/uckf-B01'),
          ),
        },
        {
          path:'vvkf-A01',
          element:React.lazy(
              () => import( '@/pages/h-k/vvkf-A01'),
          ),
        },
        {
          path:'gdtkf-s01',
          element:React.lazy(
              () => import( '@/pages/h-k/gdtkf-s01'),
          ),
        },
        {
          path:'gdtkf-s03',
          element:React.lazy(
              () => import( '@/pages/h-k/gdtkf-s03'),
          ),
        },
        {
          path:'gdtkf-s02',
          element:React.lazy(
              () => import( '@/pages/h-k/gdtkf-s02'),
          ),
        },
        {
          path:'gdtkf-A16',
          element:React.lazy(
              () => import( '@/pages/h-k/gdtkf-A16'),
          ),
        },

      ]
    },
    {
      path:'wx-w/*',
      element:React.lazy(
          () => import( '@/pages/wx-w'),
      ),
      children:[
        {
          path:'bdskf-bds01/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-w/bdskf-bds01'),
          ),
        },
        {
          path:'bdpc-01/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-w/bdpc-01'),
          ),
        },
        {
          path:'xhskf-a01/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-w/xhskf-a01'),
          ),
        },
        {
          path:'bdkf-a01/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-w/bdkf-a01'),
          ),
        },
        {
          path:'opkf-A01/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-w/opkf-A01'),
          ),
        },
        {
          path:'offline-a01/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-w/offline-a01'),
          ),
        },
        {
          path:'ttkf-D20/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-w/ttkf-D20'),
          ),
        },
        {
          path:'gdtkf-t08/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-w/gdtkf-t08'),
          ),
        },
        {
          path:'ttkf-D19/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-w/ttkf-D19'),
          ),
        },
        {
          path:'sph-c03/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-w/sph-c03'),
          ),
        },
        {
          path:'sph-c04/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-w/sph-c04'),
          ),
        },
        {
          path:'ttkf-D18/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-w/ttkf-D18'),
          ),
        },
        {
          path:'kskf-L02/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-w/kskf-L02'),
          ),
        },
        {
          path:'ttkf-D17/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-w/ttkf-D17'),
          ),
        },
        {
          path:'gdtkf-A01/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-w/gdtkf-A01'),
          ),
        },
        {
          path:'ttkf-D15/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-w/ttkf-D15'),
          ),
        },
        {
          path:'ttkf-D16/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-w/ttkf-D16'),
          ),
        },
        {
          path:'gdtkf-t07/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-w/gdtkf-t07'),
          ),
        },
        {
          path:'vvkf-A02/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-w/vvkf-A02'),
          ),
        },
        {
          path:'uckf-B02/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-w/uckf-B02'),
          ),
        },
        {
          path:'kskf-L01/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-w/kskf-L01'),
          ),
        },
        {
          path:'ttkf-D01/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-w/ttkf-D01'),
          ),
        },
        {
          path:'sph-c01/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-w/sph-c01'),
          ),
        },
        {
          path:'ttkf-DP05/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-w/ttkf-DP05'),
          ),
        },
        {
          path:'gdtkf-Ap03/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-w/gdtkf-Ap03'),
          ),
        },
        {
          path:'sph-c02/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-w/sph-c02'),
          ),
        },
        {
          path:'gdtkf-t06/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-w/gdtkf-t06'),
          ),
        },
        {
          path:'gdtkf-t05/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-w/gdtkf-t05'),
          ),
        },
        {
          path:'mp-a28/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-w/mp-a28'),
          ),
        },
        {
          path:'mp-a30/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-w/mp-a30'),
          ),
        },
        {
          path:'mp-a31/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-w/mp-a31'),
          ),
        },
        {
          path:'mp-a32/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-w/mp-a32'),
          ),
        },
        {
          path:'mp-a38/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-w/mp-a38'),
          ),
        },
        {
          path:'mp-a41/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-w/mp-a41'),
          ),
        },
        {
          path:'mp-a46/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-w/mp-a46'),
          ),
        },
        {
          path:'mp-a47/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-w/mp-a47'),
          ),
        },
        {
          path:'mp-a51/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-w/mp-a51'),
          ),
        },
        {
          path:'mp-a69/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-w/mp-a69'),
          ),
        },
        {
          path:'mp-a72/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-w/mp-a72'),
          ),
        },
        {
          path:'mp-a75/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-w/mp-a75'),
          ),
        },
        {
          path:'mp-a76/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-w/mp-a76'),
          ),
        },
        {
          path:'mp-a78/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-w/mp-a78'),
          ),
        },
        {
          path:'mp-a90/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-w/mp-a90'),
          ),
        },
        {
          path:'mp-a91/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-w/mp-a91'),
          ),
        },
        {
          path:'mp-a92/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-w/mp-a92'),
          ),
        },
        {
          path:'mp-a93/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-w/mp-a93'),
          ),
        },
        {
          path:'mp-a94/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-w/mp-a94'),
          ),
        },
        {
          path:'mp-a95/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-w/mp-a95'),
          ),
        },
        {
          path:'mp-a96/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-w/mp-a96'),
          ),
        },
        {
          path:'mp-a97/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-w/mp-a97'),
          ),
        },
        {
          path:'mp-a98/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-w/mp-a98'),
          ),
        },
        {
          path:'mp-a99/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-w/mp-a99'),
          ),
        },
        {
          path:'mp-a100/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-w/mp-a100'),
          ),
        },
        {
          path:'mp-a101/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-w/mp-a101'),
          ),
        },
        {
          path:'mp-a102/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-w/mp-a102'),
          ),
        },
        {
          path:'mp-a103/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-w/mp-a103'),
          ),
        },
        {
          path:'mp-a104/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-w/mp-a104'),
          ),
        },
        {
          path:'mp-a105/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-w/mp-a105'),
          ),
        },
        {
          path:'mp-a106/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-w/mp-a106'),
          ),
        },
      ]
    },
    {
      path:'h-w/*',
      element:React.lazy(
        () => import  ('@/pages/h-w'),
      ),
      children:[
        {
          path:'mp-amw',
          element:React.lazy(
              () => import( '@/pages/h-w/mp-amw'),
          ),
        },
        {
          path:'mp-a133',
          element:React.lazy(
              () => import( '@/pages/h-w/mp-a133'),
          ),
        },
        {
          path:'mp-w119',
          element:React.lazy(
              () => import( '@/pages/h-w/mp-w119'),
          ),
        },
        {
          path:'mp-w118',
          element:React.lazy(
              () => import( '@/pages/h-w/mp-w118'),
          ),
        },
        {
          path:'mp-w111',
          element:React.lazy(
              () => import( '@/pages/h-w/mp-w111'),
          ),
        },
        {
          path:'mp-w113',
          element:React.lazy(
              () => import( '@/pages/h-w/mp-w113'),
          ),
        },
        {
          path:'mp-w115',
          element:React.lazy(
              () => import( '@/pages/h-w/mp-w115'),
          ),
        },
        {
          path:'mp-w116',
          element:React.lazy(
              () => import( '@/pages/h-w/mp-w116'),
          ),
        },
        {
          path:'ttkf-D04',
          element:React.lazy(
              () => import( '@/pages/h-w/ttkf-D04'),
          ),
        },
        {
          path:'kskf-Lp06',
          element:React.lazy(
              () => import( '@/pages/h-w/kskf-Lp06'),
          ),
        },
        {
          path:'kskf-Lp05',
          element:React.lazy(
              () => import( '@/pages/h-w/kskf-Lp05'),
          ),
        },
        {
          path:'sph-c12',
          element:React.lazy(
              () => import( '@/pages/h-w/sph-c12'),
          ),
        },
        {
          path:'sph-c11',
          element:React.lazy(
              () => import( '@/pages/h-w/sph-c11'),
          ),
        },
        {
          path:'sph-c10',
          element:React.lazy(
              () => import( '@/pages/h-w/sph-c10'),
          ),
        },
        {
          path:'ttkf-D01',
          element:React.lazy(
              () => import( '@/pages/h-w/ttkf-D01'),
          ),
        },
        {
          path:'ttkf-D02',
          element:React.lazy(
              () => import( '@/pages/h-w/ttkf-D02'),
          ),
        },
        {
          path:'ttkf-D03',
          element:React.lazy(
              () => import( '@/pages/h-w/ttkf-D03'),
          ),
        },
        {
          path:'gdtkf-t01',
          element:React.lazy(
              () => import( '@/pages/h-w/gdtkf-t01'),
          ),
        },
        {
          path:'gdtkf-t02',
          element:React.lazy(
              () => import( '@/pages/h-w/gdtkf-t02'),
          ),
        },
        {
          path:'gdtkf-t03',
          element:React.lazy(
              () => import( '@/pages/h-w/gdtkf-t03'),
          ),
        },
        {
          path:'vvkf-A01',
          element:React.lazy(
              () => import( '@/pages/h-w/vvkf-A01'),
          ),
        },
        {
          path:'vvkf-A02',
          element:React.lazy(
              () => import( '@/pages/h-w/vvkf-A02'),
          ),
        },
        {
          path:'vvkf-A03',
          element:React.lazy(
              () => import( '@/pages/h-w/vvkf-A03'),
          ),
        },
        {
          path:'uckf-B01',
          element:React.lazy(
              () => import( '@/pages/h-w/uckf-B01'),
          ),
        },
        {
          path:'uckf-B02',
          element:React.lazy(
              () => import( '@/pages/h-w/uckf-B02'),
          ),
        },
        {
          path:'uckf-B03',
          element:React.lazy(
              () => import( '@/pages/h-w/uckf-B03'),
          ),
        },
        {
          path:'mp-w21',
          element:React.lazy(
              () => import( '@/pages/h-w/mpw21'),
          ),
        },
        {
          path:'mp-w28',
          element:React.lazy(
              () => import( '@/pages/h-w/mpw28'),
          ),
        },
        {
          path:'mp-w30',
          element:React.lazy(
              () => import( '@/pages/h-w/mpw30'),
          ),
        },
        {
          path:'mp-w31',
          element:React.lazy(
              () => import( '@/pages/h-w/mpw31'),
          ),
        },
        {
          path:'mp-w32',
          element:React.lazy(
              () => import( '@/pages/h-w/mpw32'),
          ),
        },
        {
          path:'mp-w38',
          element:React.lazy(
              () => import( '@/pages/h-w/mpw38'),
          ),
        },
        {
          path:'mp-w41',
          element:React.lazy(
              () => import( '@/pages/h-w/mpw41'),
          ),
        },
        {
          path:'mp-w41',
          element:React.lazy(
              () => import( '@/pages/h-w/mpw41'),
          ),
        },
        {
          path:'mp-w46',
          element:React.lazy(
              () => import( '@/pages/h-w/mpw46'),
          ),
        },
        {
          path:'mp-w47',
          element:React.lazy(
              () => import( '@/pages/h-w/mpw47'),
          ),
        },
        {
          path:'mp-w51',
          element:React.lazy(
              () => import( '@/pages/h-w/mpw51'),
          ),
        },
        {
          path:'mp-w52',
          element:React.lazy(
              () => import( '@/pages/h-w/mpw52'),
          ),
        },
        {
          path:'mp-w53',
          element:React.lazy(
              () => import( '@/pages/h-w/mpw53'),
          ),
        },
        {
          path:'mp-w58',
          element:React.lazy(
              () => import( '@/pages/h-w/mpw58'),
          ),
        },
        {
          path:'mp-w59',
          element:React.lazy(
              () => import( '@/pages/h-w/mpw59'),
          ),
        },
        {
          path:'mp-w69',
          element:React.lazy(
              () => import( '@/pages/h-w/mpw69'),
          ),
        },
        {
          path:'mp-w72',
          element:React.lazy(
              () => import( '@/pages/h-w/mpw72'),
          ),
        },
        {
          path:'mp-w75',
          element:React.lazy(
              () => import( '@/pages/h-w/mpw75'),
          ),
        },
        {
          path:'mp-w76',
          element:React.lazy(
              () => import( '@/pages/h-w/mpw76'),
          ),
        },
        {
          path:'mp-w78',
          element:React.lazy(
              () => import( '@/pages/h-w/mpw78'),
          ),
        },
        {
          path:'mp-w89',
          element:React.lazy(
              () => import( '@/pages/h-w/mpw89'),
          ),
        },
        {
          path:'mp-w90',
          element:React.lazy(
              () => import( '@/pages/h-w/mpw90'),
          ),
        },
        {
          path:'mp-w91',
          element:React.lazy(
              () => import( '@/pages/h-w/mpw91'),
          ),
        },
        {
          path:'mp-w92',
          element:React.lazy(
              () => import( '@/pages/h-w/mpw92'),
          ),
        },
        {
          path:'mp-w93',
          element:React.lazy(
              () => import( '@/pages/h-w/mpw93'),
          ),
        },
        {
          path:'mp-w94',
          element:React.lazy(
              () => import( '@/pages/h-w/mpw94'),
          ),
        },
        {
          path:'mp-w95',
          element:React.lazy(
              () => import( '@/pages/h-w/mpw95'),
          ),
        },
        {
          path:'mp-w96',
          element:React.lazy(
              () => import( '@/pages/h-w/mpw96'),
          ),
        },
        {
          path:'mp-w97',
          element:React.lazy(
              () => import( '@/pages/h-w/mpw97'),
          ),
        },
        {
          path:'mp-w98',
          element:React.lazy(
              () => import( '@/pages/h-w/mpw98'),
          ),
        },
        {
          path:'mp-w99',
          element:React.lazy(
              () => import( '@/pages/h-w/mpw99'),
          ),
        },
        {
          path:'mp-w100',
          element:React.lazy(
              () => import( '@/pages/h-w/mpw100'),
          ),
        },
        {
          path:'mp-w101',
          element:React.lazy(
              () => import( '@/pages/h-w/mpw101'),
          ),
        },
        {
          path:'mp-w102',
          element:React.lazy(
              () => import( '@/pages/h-w/mpw102'),
          ),
        },
        {
          path:'mp-w103',
          element:React.lazy(
              () => import( '@/pages/h-w/mpw103'),
          ),
        },
        {
          path:'mp-w104',
          element:React.lazy(
              () => import( '@/pages/h-w/mpw104'),
          ),
        },
        {
          path:'mp-w105',
          element:React.lazy(
              () => import( '@/pages/h-w/mp-w105'),
          ),
        },
          {
              path:'mp-w106',
              element:React.lazy(
                  () => import( '@/pages/h-w/mp-w106'),
              ),
          },
      ]
    },
    {
      path: 'wxj-p-w/*',
      element: React.lazy(
          ()=>import('@/pages/wxj-p-w'),
      ),
      children: [
        {
          path:'gdtkf-tp29/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/gdtkf-tp29'),
          ),
        },
        {
          path:'gdtkf-tp27/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/gdtkf-tp27'),
          ),
        },
        {
          path:'gdtkf-tp25/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/gdtkf-tp25'),
          ),
        },
        {
          path:'ttkf-Dp49/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/ttkf-Dp49'),
          ),
        },
        {
          path:'ttkf-Dp46/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/ttkf-Dp46'),
          ),
        },
        {
          path:'ttkf-Dp47/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/ttkf-Dp47'),
          ),
        },
        {
          path:'ttkf-Dp45/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/ttkf-Dp45'),
          ),
        },
        {
          path:'ttkf-Dp44/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/ttkf-Dp44'),
          ),
        },
        {
          path:'ttkf-Dp42/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/ttkf-Dp42'),
          ),
        },
        {
          path:'ttkf-Dp40/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/ttkf-Dp40'),
          ),
        },
        {
          path:'ttkf-Dp39/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/ttkf-Dp39'),
          ),
        },
        {
          path:'ttkf-Dp38/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/ttkf-Dp38'),
          ),
        },
        {
          path:'ttkf-Dp37/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/ttkf-Dp37'),
          ),
        },
        {
          path:'ttkf-Dp33/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/ttkf-Dp33'),
          ),
        },
        {
          path:'ttkf-Dp32/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/ttkf-Dp32'),
          ),
        },
        {
          path:'uckf-Bp05/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/uckf-Bp05'),
          ),
        },
        {
          path:'uckf-Bp04/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/uckf-Bp04'),
          ),
        },
        {
          path:'xhskf-Ap01/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/xhskf-Ap01'),
          ),
        },
        {
          path:'kskf-Lp05/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/kskf-Lp05'),
          ),
        },
        {
          path:'kskf-Lp04/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/kskf-Lp04'),
          ),
        },
        {
          path:'kskf-Lp03/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/kskf-Lp03'),
          ),
        },
        {
          path:'gdtkf-tp23/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/gdtkf-tp23'),
          ),
        },
        {
          path:'bdkf-Bp04/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/bdkf-Bp04'),
          ),
        },
        {
          path:'bdkf-Bp12/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/bdkf-Bp12'),
          ),
        },
        {
          path:'bdkf-Bp11/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/bdkf-Bp11'),
          ),
        },
        {
          path:'gdtkf-tp22/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/gdtkf-tp22'),
          ),
        },
        {
          path:'gdtkf-tp21/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/gdtkf-tp21'),
          ),
        },
        {
          path:'ttkf-Dp31/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/ttkf-Dp31'),
          ),
        },
        {
          path:'ttkf-Dp30/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/ttkf-Dp30'),
          ),
        },
        {
          path:'gdtkf-tp19/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/gdtkf-tp19'),
          ),
        },
        {
          path:'gdtkf-tp18/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/gdtkf-tp18'),
          ),
        },
        {
          path:'gdtkf-tp17/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/gdtkf-tp17'),
          ),
        },
        {
          path:'bdkf-Bp05/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/bdkf-Bp05'),
          ),
        },
        {
          path:'bdkf-Bp02/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/bdkf-Bp02'),
          ),
        },
        {
          path:'bdkf-Bp01/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/bdkf-Bp01'),
          ),
        },
        {
          path:'hwkf-Hp02/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/hwkf-Hp02'),
          ),
        },
        {
          path:'hwkf-Hp01/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/hwkf-Hp01'),
          ),
        },
        {
          path:'opkf-Cp01/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/opkf-Cp01'),
          ),
        },
        {
          path:'gdtkf-tp14/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/gdtkf-tp14'),
          ),
        },
        {
          path:'gdtkf-tp13/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/gdtkf-tp13'),
          ),
        },
        {
          path:'gdtkf-tp12/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/gdtkf-tp12'),
          ),
        },
        {
          path:'gdtkf-tp11/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/gdtkf-tp11'),
          ),
        },
        {
          path:'gdtkf-tp10/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/gdtkf-tp10'),
          ),
        },
        {
          path:'gdtkf-tp09/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/gdtkf-tp09'),
          ),
        },
        {
          path:'ttkf-Dp22/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/ttkf-Dp22'),
          ),
        },
        {
          path:'opkf-A01/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/opkf-A01'),
          ),
        },
        {
          path:'ttkf-Dp21/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/ttkf-Dp21'),
          ),
        },
        {
          path:'gdtkf-tp08/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/gdtkf-tp08'),
          ),
        },
        {
          path:'gdtkf-tp07/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/gdtkf-tp07'),
          ),
        },
        {
          path:'gdtkf-tp06/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/gdtkf-tp06'),
          ),
        },
        {
          path:'ttkf-Dp20/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/ttkf-Dp20'),
          ),
        },
        {
          path:'ttkf-Dp19/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/ttkf-Dp19'),
          ),
        },
        {
          path:'ttkf-Dp18/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/ttkf-Dp18'),
          ),
        },
        {
          path:'ttkf-Dp17/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/ttkf-Dp17'),
          ),
        },
        {
          path:'ttkf-Dp16/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/ttkf-Dp16'),
          ),
        },
        {
          path:'vvkf-Ap03/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/vvkf-Ap03'),
          ),
        },
        {
          path:'gdtkf-tp05/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/gdtkf-tp05'),
          ),
        },
        {
          path:'ttkf-Dp15/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/ttkf-Dp15'),
          ),
        },
        {
          path:'sph-c25/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/sph-c25'),
          ),
        },
        {
          path:'sph-c24/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/sph-c24'),
          ),
        },
        {
          path:'mppay-35/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/mppay-35'),
          ),
        },
        {
          path:'mppay-33/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/mppay-33'),
          ),
        },
        {
          path:'mppay-29/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/mppay-29'),
          ),
        },
        {
          path:'mppay-27/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/mppay-27'),
          ),
        },
        {
          path:'vvkf-Ap02/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/vvkf-Ap02'),
          ),
        },
        {
          path:'mppay-26/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/mppay-26'),
          ),
        },
        {
          path:'mppay-24/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/mppay-24'),
          ),
        },
        {
          path:'mppay-23/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/mppay-23'),
          ),
        },
        {
          path:'mppay-12/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/mppay-12'),
          ),
        },
        {
          path:'mppay-28/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/mppay-28'),
          ),
        },
        {
          path:'mppay-22/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/mppay-22'),
          ),
        },
        {
          path:'ttkf-Dp14/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/ttkf-Dp14'),
          ),
        },
        {
          path:'ttkf-Dp13/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/ttkf-Dp13'),
          ),
        },
        {
          path:'ttkf-Dp12/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/ttkf-Dp12'),
          ),
        },
        {
          path:'ttkf-Dp11/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/ttkf-Dp11'),
          ),
        },
        {
          path:'ttkf-Dp10/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/ttkf-Dp10'),
          ),
        },
        {
          path:'ttkf-Dp09/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/ttkf-Dp09'),
          ),
        },
        {
          path:'gdtkf-tp04/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/gdtkf-tp04'),
          ),
        },
        {
          path:'vvkf-Ap01/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/vvkf-Ap01'),
          ),
        },
        {
          path:'uckf-Bp03/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/uckf-Bp03'),
          ),
        },
        {
          path:'uckf-Bp02/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/uckf-Bp02'),
          ),
        },
        {
          path:'uckf-Bp01/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/uckf-Bp01'),
          ),
        },
        {
          path:'kskf-Lp02/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/kskf-Lp02'),
          ),
        },
        {
          path:'gdtkf-tp03/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/gdtkf-tp03'),
          ),
        },
        {
          path:'kskf-Lp01/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/kskf-Lp01'),
          ),
        },
        {
          path:'ttkf-Dp08/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/ttkf-Dp08'),
          ),
        },
        {
          path:'ttkf-Dp07/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/ttkf-Dp07'),
          ),
        },
        {
          path:'ttkf-Dp06/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/ttkf-Dp06'),
          ),
        },
        {
          path:'ttkf-Dp05/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/ttkf-Dp05'),
          ),
        },
        {
          path:'gdtkf-tp01/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/gdtkf-tp01'),
          ),
        },
        {
          path:'gdtkf-tp02/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/gdtkf-tp02'),
          ),
        },
        {
          path:'ttkf-Dp02/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/ttkf-Dp02'),
          ),
        },
        {
          path:'ttkf-Dp03/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/ttkf-Dp03'),
          ),
        },
        {
          path:'ttkf-Dp04/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/ttkf-Dp04'),
          ),
        },
        {
          path:'ttkf-Dp01/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/ttkf-Dp01'),
          ),
        },
        {
          path:'wxj-Ap01/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/wxj-Ap01'),
          ),
        },
        {
          path:'sph-c26/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-w/sph-c26'),
          ),
        },

      ]
    },
    {
      path: 'wxj-w/*',
      element: React.lazy(
          ()=>import('@/pages/wxj-w'),
      ),
      children: [
        {
          path:'xhskf-A03',
          element:React.lazy(
              () => import( '@/pages/wxj-w/xhskf-A03'),
          ),
        },
        {
          path:'xhskf-A02',
          element:React.lazy(
              () => import( '@/pages/wxj-w/xhskf-A02'),
          ),
        },
        {
          path:'hwkf-H01',
          element:React.lazy(
              () => import( '@/pages/wxj-w/hwkf-H01'),
          ),
        },
        {
          path:'xhskf-A01',
          element:React.lazy(
              () => import( '@/pages/wxj-w/xhskf-A01'),
          ),
        },
        {
          path:'bdkf-B01',
          element:React.lazy(
              () => import( '@/pages/wxj-w/bdkf-B01'),
          ),
        },
        {
          path:'bdkf-B10',
          element:React.lazy(
              () => import( '@/pages/wxj-w/bdkf-B10'),
          ),
        },
        {
          path:'ttkf-D16',
          element:React.lazy(
              () => import( '@/pages/wxj-w/ttkf-D16'),
          ),
        },
        {
          path:'opkf-A01',
          element:React.lazy(
              () => import( '@/pages/wxj-w/opkf-A01'),
          ),
        },
        {
          path:'mp-a113',
          element:React.lazy(
              () => import( '@/pages/wxj-w/mp-a113'),
          ),
        },
        {
          path:'sph-c27',
          element:React.lazy(
              () => import( '@/pages/wxj-w/sph-c27'),
          ),
        },
        {
          path:'mp-a111',
          element:React.lazy(
              () => import( '@/pages/wxj-w/mp-a111'),
          ),
        },
        {
          path:'mp-a78',
          element:React.lazy(
              () => import( '@/pages/wxj-w/mp-a78'),
          ),
        },
        {
          path:'mp-a69',
          element:React.lazy(
              () => import( '@/pages/wxj-w/mp-a69'),
          ),
        },
        {
          path:'mp-a96',
          element:React.lazy(
              () => import( '@/pages/wxj-w/mp-a96'),
          ),
        },
        {
          path:'mp-a93',
          element:React.lazy(
              () => import( '@/pages/wxj-w/mp-a93'),
          ),
        },
        {
          path:'mp-a72',
          element:React.lazy(
              () => import( '@/pages/wxj-w/mp-a72'),
          ),
        },
        {
          path:'mp-a51',
          element:React.lazy(
              () => import( '@/pages/wxj-w/mp-a51'),
          ),
        },
        {
          path:'mp-a47',
          element:React.lazy(
              () => import( '@/pages/wxj-w/mp-a47'),
          ),
        },
        {
          path:'mp-a32',
          element:React.lazy(
              () => import( '@/pages/wxj-w/mp-a32'),
          ),
        },
        {
          path:'mp-a30',
          element:React.lazy(
              () => import( '@/pages/wxj-w/mp-a30'),
          ),
        },
        {
          path:'mp-a38',
          element:React.lazy(
              () => import( '@/pages/wxj-w/mp-a38'),
          ),
        },
        {
          path:'mp-a41',
          element:React.lazy(
              () => import( '@/pages/wxj-w/mp-a41'),
          ),
        },
        {
          path:'mp-a31',
          element:React.lazy(
              () => import( '@/pages/wxj-w/mp-a31'),
          ),
        },
        {
          path:'mp-a75',
          element:React.lazy(
              () => import( '@/pages/wxj-w/mp-a75'),
          ),
        },
        {
          path:'mp-a105',
          element:React.lazy(
              () => import( '@/pages/wxj-w/mp-a105'),
          ),
        },
          {
              path:'mp-a92',
              element:React.lazy(
                  () => import( '@/pages/wxj-w/mp-a92'),
              ),
          },
        {
          path:'mp-a28',
          element:React.lazy(
              () => import( '@/pages/wxj-w/mp-a28'),
          ),
        },
        {
          path:'mp-a90',
          element:React.lazy(
              () => import( '@/pages/wxj-w/mp-a90'),
          ),
        },
        {
          path:'mp-a110',
          element:React.lazy(
              () => import( '@/pages/wxj-w/mp-a110'),
          ),
        },
        {
          path:'ttkf-D12',
          element:React.lazy(
              () => import( '@/pages/wxj-w/ttkf-D12'),
          ),
        },
        {
          path:'ttkf-D11',
          element:React.lazy(
              () => import( '@/pages/wxj-w/ttkf-D11'),
          ),
        },
        {
          path:'ttkf-D10',
          element:React.lazy(
              () => import( '@/pages/wxj-w/ttkf-D10'),
          ),
        },
        {
          path:'ttkf-D09',
          element:React.lazy(
              () => import( '@/pages/wxj-w/ttkf-D09'),
          ),
        },
        {
          path:'ttkf-D08',
          element:React.lazy(
              () => import( '@/pages/wxj-w/ttkf-D08'),
          ),
        },
        {
          path:'ttkf-D07',
          element:React.lazy(
              () => import( '@/pages/wxj-w/ttkf-D07'),
          ),
        },
        {
          path:'kskf-L03',
          element:React.lazy(
              () => import( '@/pages/wxj-w/kskf-L03'),
          ),
        },
        {
          path:'kskf-L02',
          element:React.lazy(
              () => import( '@/pages/wxj-w/kskf-L02'),
          ),
        },
        {
          path:'kskf-L01',
          element:React.lazy(
              () => import( '@/pages/wxj-w/kskf-L01'),
          ),
        },
        {
          path:'gdtkf-t04',
          element:React.lazy(
              () => import( '@/pages/wxj-w/gdtkf-t04'),
          ),
        },
        {
          path:'gdtkf-t03',
          element:React.lazy(
              () => import( '@/pages/wxj-w/gdtkf-t03'),
          ),
        },
        {
          path:'gdtkf-t02',
          element:React.lazy(
              () => import( '@/pages/wxj-w/gdtkf-t02'),
          ),
        },
        {
          path:'gdtkf-t01',
          element:React.lazy(
              () => import( '@/pages/wxj-w/gdtkf-t01'),
          ),
        },
        {
          path:'ttkf-D06',
          element:React.lazy(
              () => import( '@/pages/wxj-w/ttkf-D06'),
          ),
        },
        {
          path:'ttkf-D05',
          element:React.lazy(
              () => import( '@/pages/wxj-w/ttkf-D05'),
          ),
        },
        {
          path:'ttkf-D04',
          element:React.lazy(
              () => import( '@/pages/wxj-w/ttkf-D04'),
          ),
        },
        {
          path:'ttkf-D03',
          element:React.lazy(
              () => import( '@/pages/wxj-w/ttkf-D03'),
          ),
        },
        {
          path:'ttkf-D02',
          element:React.lazy(
              () => import( '@/pages/wxj-w/ttkf-D02'),
          ),
        },
        {
          path:'ttkf-D01',
          element:React.lazy(
              () => import( '@/pages/wxj-w/ttkf-D01'),
          ),
        },
        {
          path:'ttkf-a01',
          element:React.lazy(
              () => import( '@/pages/wxj-w/ttkf-a01'),
          ),
        },
        {
          path:'mp-a99',
          element:React.lazy(
              () => import( '@/pages/wxj-w/mp-a99'),
          ),
        },
        {
          path:'mp-a46',
          element:React.lazy(
              () => import( '@/pages/wxj-w/mp-a46'),
          ),
        },
        {
          path:'mp-a94',
          element:React.lazy(
              () => import( '@/pages/wxj-w/mp-a94'),
          ),
        },

      ]
    },
    {
      path:'wx-pay-aw/*',
      element:React.lazy(
        () => import( '@/pages/wx-pay-aw'),
      ),
      children:[
        {
          path:'sph-c41/:pageType',
          element:React.lazy(
            () => import( '@/pages/wx-pay-aw/sph-c41'),
          ),
        }
      ]
    },
    {
      path:'wx-pay-w/*',
      element:React.lazy(
          () => import( '@/pages/wx-pay-w'),
      ),
      children:[
        {
          path:'360skf-a01/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/360skf-a01'),
          ),
        },
        {
          path:'360pc-a01/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/360pc-a01'),
          ),
        },
        {
          path:'bdskf-bds01/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/bdskf-bds01'),
          ),
        },
        {
          path:'sph-c82/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/sph-c82'),
          ),
        },
        {
          path:'sph-c80/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/sph-c80'),
          ),
        },
        {
          path:'sph-c76/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/sph-c76'),
          ),
        },
        {
          path:'bdkf-bp01/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/bdkf-bp01'),
          ),
        },
        {
          path:'sph-c75/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/sph-c75'),
          ),
        },
        {
          path:'sph-c74/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/sph-c74'),
          ),
        },
        {
          path:'sph-c72/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/sph-c72'),
          ),
        },
        {
          path:'sph-c71/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/sph-c71'),
          ),
        },
        {
          path:'sph-c69/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/sph-c69'),
          ),
        },
        {
          path:'sph-c68/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/sph-c68'),
          ),
        },
        {
          path:'bdpc-Bp02/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/bdpc-Bp02'),
          ),
        },
        {
          path:'bdpc-Bp01/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/bdpc-Bp01'),
          ),
        },
        {
          path:'bdpc-04/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/bdpc-04'),
          ),
        },
        {
          path:'sph-c66/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/sph-c66'),
          ),
        },
        {
          path:'sph-c64/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/sph-c64'),
          ),
        },
        {
          path:'sph-c57/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/sph-c57'),
          ),
        },
        {
          path:'mp-p132/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/mp-p132'),
          ),
        },
        {
          path:'mp-p131/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/mp-p131'),
          ),
        },
        {
          path:'mp-p130/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/mp-p130'),
          ),
        },
        {
          path:'mp-b111/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/mp-b111'),
          ),
        },
        {
          path:'mp-b132/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/mp-b132'),
          ),
        },
        {
          path:'mp-b129/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/mp-b129'),
          ),
        },
        {
          path:'mp-b128/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/mp-b128'),
          ),
        },
        {
          path:'mp-p94/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/mp-p94'),
          ),
        },
        {
          path:'sph-c56/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/sph-c56'),
          ),
        },
        {
          path:'mp-b127/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/mp-b127'),
          ),
        },
        {
          path:'sph-c55/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/sph-c55'),
          ),
        },
        {
          path:'sph-c54/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/sph-c54'),
          ),
        },
        {
          path:'sph-c53/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/sph-c53'),
          ),
        },
        {
          path:'mp-b126/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/mp-b126'),
          ),
        },
        {
          path:'mp-b125/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/mp-b125'),
          ),
        },
        {
          path:'sph-c52/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/sph-c52'),
          ),
        },
        {
          path:'mp-b124/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/mp-b124'),
          ),
        },
        {
          path:'mp-b123/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/mp-b123'),
          ),
        },
        {
          path:'sph-c51/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/sph-c51'),
          ),
        },
        {
          path:'sph-c50/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/sph-c50'),
          ),
        },
        {
          path:'sph-c49/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/sph-c49'),
          ),
        },
        {
          path:'mp-p122/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/mp-p122'),
          ),
        },
        {
          path:'mp-p121/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/mp-p121'),
          ),
        },
        {
          path:'mp-b122/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/mp-b122'),
          ),
        },
        {
          path:'mp-b121/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/mp-b121'),
          ),
        },
        {
          path:'sph-c48/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/sph-c48'),
          ),
        },
        {
          path:'sph-c47/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/sph-c47'),
          ),
        },
        {
          path:'mp-p117/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/mp-p117'),
          ),
        },
          {
              path:'mp-b117/:pageType',
              element:React.lazy(
                  () => import( '@/pages/wx-pay-w/mp-b117'),
              ),
          },
          {
              path:'sph-c46/:pageType',
              element:React.lazy(
                  () => import( '@/pages/wx-pay-w/sph-c46'),
              ),
          },
          {
              path:'mp-b120/:pageType',
              element:React.lazy(
                  () => import( '@/pages/wx-pay-w/mp-b120'),
              ),
          },
        {
          path:'sph-c45/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/sph-c45'),
          ),
        },
        {
          path:'mp-b113/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/mp-b113'),
          ),
        },
        {
          path:'mp-b118/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/mp-b118'),
          ),
        },
        {
          path:'mp-b119/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/mp-b119'),
          ),
        },
        {
          path:'mp-p119/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/mp-p119'),
          ),
        },
        {
          path:'sph-c44/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/sph-c44'),
          ),
        },
        {
          path:'sph-c43/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/sph-c43'),
          ),
        },
        {
          path:'mp-p113/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/mp-p113'),
          ),
        },
        {
          path:'mp-p111/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/mp-p111'),
          ),
        },
        {
          path:'mp-p96/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/mp-p96'),
          ),
        },
        {
          path:'mp-p30/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/mp-p30'),
          ),
        },
        {
          path:'sph-c41/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/sph-c41'),
          ),
        },
        {
          path:'mp-p28/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/mp-p28'),
          ),
        },
        {
          path:'mp-p118/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/mp-p118'),
          ),
        },
        {
          path:'sph-c40/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/sph-c40'),
          ),
        },
        {
          path:'sph-c39/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/sph-c39'),
          ),
        },
        {
          path:'sph-c37/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/sph-c37'),
          ),
        },
        {
          path:'sph-c35/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/sph-c35'),
          ),
        },
        {
          path:'sph-c33/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/sph-c33'),
          ),
        },
        {
          path:'opkf-A01/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/opkf-A01'),
          ),
        },
        {
          path:'sph-c32/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/sph-c32'),
          ),
        },
        {
          path:'sph-c31/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/sph-c31'),
          ),
        },
        {
          path:'sph-c30/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/sph-c30'),
          ),
        },
        {
          path:'sph-c29/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/sph-c29'),
          ),
        },
        {
          path:'sph-c28/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/sph-c28'),
          ),
        },
        {
          path:'ttkf-Dp23/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/ttkf-Dp23'),
          ),
        },
        {
          path:'sph-c23/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/sph-c23'),
          ),
        },
        {
          path:'gdtkf-tp06/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/gdtkf-tp06'),
          ),
        },
        {
          path:'ttkf-Dp22/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/ttkf-Dp22'),
          ),
        },
        {
          path:'kskf-Lp07/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/kskf-Lp07'),
          ),
        },
        {
          path:'sph-c21/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/sph-c21'),
          ),
        },
        {
          path:'sph-c20/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/sph-c20'),
          ),
        },
        {
          path:'ttkf-Dp21/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/ttkf-Dp21'),
          ),
        },
        {
          path:'mppay-38/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/mppay-38'),
          ),
        },
        {
          path:'uckf-Bp02/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/uckf-Bp02'),
          ),
        },
        {
          path:'uckf-Bp03/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/uckf-Bp03'),
          ),
        },
        {
          path:'ttkf-Dp20/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/ttkf-Dp20'),
          ),
        },
        {
          path:'sph-c17/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/sph-c17'),
          ),
        },
        {
          path:'sph-c16/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/sph-c16'),
          ),
        },
        {
          path:'sph-c09/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/sph-c09'),
          ),
        },
        {
          path:'ttkf-Dp19/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/ttkf-Dp19'),
          ),
        },
        {
          path:'ttkf-Dp18/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/ttkf-Dp18'),
          ),
        },
        {
          path:'sph-c08/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/sph-c08'),
          ),
        },
        {
          path:'sph-c07/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/sph-c07'),
          ),
        },
        {
          path:'gdtkf-tp05/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/gdtkf-tp05'),
          ),
        },
        {
          path:'ttkf-Dp17/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/ttkf-Dp17'),
          ),
        },
        {
          path:'ttkf-Dp16/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/ttkf-Dp16'),
          ),
        },
        {
          path:'ttkf-Dp15/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/ttkf-Dp15'),
          ),
        },
        {
          path:'kskf-Lp04/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/kskf-Lp04'),
          ),
        },
        {
          path:'kskf-Lp03/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/kskf-Lp03'),
          ),
        },
        {
          path:'ttkf-Dp07/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/ttkf-Dp07'),
          ),
        },
        {
          path:'mppay-09/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/mppay-09'),
          ),
        },
        {
          path:'mppay-12/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/mppay-12'),
          ),
        },
        {
          path:'mppay-15/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/mppay-15'),
          ),
        },
        {
          path:'mppay-21/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/mppay-21'),
          ),
        },
        {
          path:'mppay-22/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/mppay-22'),
          ),
        },
        {
          path:'mppay-23/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/mppay-23'),
          ),
        },
        {
          path:'mppay-24/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/mppay-24'),
          ),
        },
        {
          path:'mppay-25/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/mppay-25'),
          ),
        },
        {
          path:'mppay-26/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/mppay-26'),
          ),
        },
        {
          path:'mppay-27/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/mppay-27'),
          ),
        },
        {
          path:'ttkf-Dp03/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/ttkf-Dp03'),
          ),
        },
        {
          path:'ttkf-Dp04/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/ttkf-Dp04'),
          ),
        },
        {
          path:'gdtkf-tp01/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/gdtkf-tp01'),
          ),
        },
        {
          path:'gdtkf-tp02/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/gdtkf-tp02'),
          ),
        },
        {
          path:'mppay-28/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/mppay-28'),
          ),
        },
        {
          path:'mppay-29/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/mppay-29'),
          ),
        },
        {
          path:'mppay-32/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/mppay-32'),
          ),
        },
        {
          path:'mppay-33/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/mppay-33'),
          ),
        },
        {
          path:'mppay-34/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/mppay-34'),
          ),
        },
        {
          path:'mppay-35/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/mppay-35'),
          ),
        },
        {
          path:'mppay-36/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/mppay-36'),
          ),
        },
        {
          path:'ttkf-Dp05/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/ttkf-Dp05'),
          ),
        },
        {
          path:'ttkf-Dp06/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/ttkf-Dp06'),
          ),
        },
        {
          path:'mppay-37/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/mppay-37'),
          ),
        },
        {
          path:'ttkf-Dp08/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/ttkf-Dp08'),
          ),
        },
        {
          path:'ttkf-Dp09/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/ttkf-Dp09'),
          ),
        },
        {
          path:'ttkf-Dp10/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/ttkf-Dp10'),
          ),
        },
        {
          path:'gdtkf-tp03/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/gdtkf-tp03'),
          ),
        },
        {
          path:'ttkf-Dp11/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/ttkf-Dp11'),
          ),
        },
        {
          path:'ttkf-Dp12/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/ttkf-Dp12'),
          ),
        },
        {
          path:'gdtkf-tp04/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/gdtkf-tp04'),
          ),
        },
        {
          path:'ttkf-Dp13/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/ttkf-Dp13'),
          ),
        },
        {
          path:'ttkf-Dp14/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/ttkf-Dp14'),
          ),
        },
        {
          path:'uckf-Bp01/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/uckf-Bp01'),
          ),
        },
        {
          path:'vvkf-Ap01/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-w/vvkf-Ap01'),
          ),
        },
      ]

    },
    {
      path:'wx-ww/*',
      element:React.lazy(
          () => import( '@/pages/wx-ww'),
      ),
      children:[
        {
          path:'mp-a132/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-ww/mp-a132'),
          ),
        },
        {
          path:'sph-c86/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-ww/sph-c86'),
          ),
        },
        {
          path:'sph-c81/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-ww/sph-c81'),
          ),
        },
        {
          path:'sph-c77/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-ww/sph-c77'),
          ),
        },
        {
          path:'sph-c65/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-ww/sph-c65'),
          ),
        },
        {
          path:'sph-c42/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-ww/sph-c42'),
          ),
        },
        {
          path:'mp-a119/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-ww/mp-a119'),
          ),
        },
        {
          path:'mp-a118/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-ww/mp-a118'),
          ),
        },
        {
          path:'mp-a117/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-ww/mp-a117'),
          ),
        },
        {
          path:'sph-c36/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-ww/sph-c36'),
          ),
        },
        {
          path:'mp-a116/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-ww/mp-a116'),
          ),
        },
        {
          path:'mp-a115/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-ww/mp-a115'),
          ),
        },
        {
          path:'mp-a114/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-ww/mp-a114'),
          ),
        },
        {
          path:'mp-a111/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-ww/mp-a111'),
          ),
        },
          {
              path:'mp-a113/:pageType',
              element:React.lazy(
                  () => import( '@/pages/wx-ww/mp-a113'),
              ),
          },
        {
          path:'sph-c22/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-ww/sph-c22'),
          ),
        },
        {
          path:'sph-c19/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-ww/sph-c19'),
          ),
        },
        {
          path:'mp-a109/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-ww/mp-a109'),
          ),
        },
        {
          path:'sph-c18/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-ww/sph-c18'),
          ),
        },
        {
          path:'sph-c15/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-ww/sph-c15'),
          ),
        },
        {
          path:'sph-c14/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-ww/sph-c14'),
          ),
        },
        {
          path:'sph-c13/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-ww/sph-c13'),
          ),
        },
        {
          path:'mp-a108/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-ww/mp-a108'),
          ),
        },
        {
          path:'sph-c06/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-ww/sph-c06'),
          ),
        },
        {
          path:'sph-c05/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-ww/sph-c05'),
          ),
        },
        {
          path:'mp-a28/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-ww/mp-a28'),
          ),
        },
        {
          path:'mp-a30/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-ww/mp-a30'),
          ),
        },
        {
          path:'mp-a31/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-ww/mp-a31'),
          ),
        },
        {
          path:'mp-a32/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-ww/mp-a32'),
          ),
        },
        {
          path:'mp-a38/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-ww/mp-a38'),
          ),
        },
        {
          path:'mp-a41/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-ww/mp-a41'),
          ),
        },
        {
          path:'mp-a46/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-ww/mp-a46'),
          ),
        },
        {
          path:'mp-a47/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-ww/mp-a47'),
          ),
        },
        {
          path:'mp-a51/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-ww/mp-a51'),
          ),
        },
        {
          path:'mp-a69/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-ww/mp-a69'),
          ),
        },
        {
          path:'mp-a72/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-ww/mp-a72'),
          ),
        },
        {
          path:'mp-a75/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-ww/mp-a75'),
          ),
        },
        {
          path:'mp-a78/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-ww/mp-a78'),
          ),
        },
        {
          path:'mp-a90/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-ww/mp-a90'),
          ),
        },
        {
          path:'mp-a92/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-ww/mp-a92'),
          ),
        },
        {
          path:'mp-a93/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-ww/mp-a93'),
          ),
        },
        {
          path:'mp-a94/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-ww/mp-a94'),
          ),
        },
        {
          path:'mp-a95/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-ww/mp-a95'),
          ),
        },
        {
          path:'mp-a96/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-ww/mp-a96'),
          ),
        },
        {
          path:'mp-a97/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-ww/mp-a97'),
          ),
        },
        {
          path:'mp-a99/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-ww/mp-a99'),
          ),
        },
        {
          path:'mp-a102/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-ww/mp-a102'),
          ),
        },
        {
          path:'mp-a105/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-ww/mp-a105'),
          ),
        },
      ]
    },
    {
      path: 'wx-l/*',
      element:React.lazy(
        () => import( '@/pages/wx-l'),
      ),
      children: [
        {
          path:'bdskf-bds01/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-l/bdskf-bds01'),
          ),
        },
        {
          path:'bdpc-02/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-l/bdpc-02'),
          ),
        },
        {
          path:'hwkf-H01/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-l/hwkf-H01'),
          ),
        },
        {
          path:'xhskf-a01/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-l/xhskf-a01'),
          ),
        },
        {
          path:'bdkf-a01/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-l/bdkf-a01'),
          ),
        },
        {
          path:'mp-a28/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-l/mp-a28'),
          ),
        },
        {
          path:'mp-a30/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-l/mp-a30'),
          ),
        },
        {
          path:'ttkf-D01/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-l/ttkf-D01'),
          ),
        },
        {
          path:'mp-a113/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-l/mp-a113'),
          ),
        },
        {
          path:'mp-a111/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-l/mp-a111'),
          ),
        },
      ]
    },
    {
      path:'wx-all/*',
      element:React.lazy(
          () => import( '@/pages/wx-all'),
      ),
      children:[
        {
          path:'sph-c85/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-all/sph-c85'),
          ),
        },
        {
          path:'sph-c79/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-all/sph-c79'),
          ),
        },
        {
          path:'sph-c78/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-all/sph-c78'),
          ),
        },
        {
          path:'sph-c73/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-all/sph-c73'),
          ),
        },
        {
          path:'sph-c59/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-all/sph-c59'),
          ),
        },
        {
          path:'sph-c67/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-all/sph-c67'),
          ),
        },

      ]
    },
    {
      path:'wx-ll/*',
      element:React.lazy(
          () => import( '@/pages/wx-ll'),
      ),
      children:[
        {
          path:'mp-a47/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-ll/mp-a47'),
          ),
        },
        {
          path:'mp-a75/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-ll/mp-a75'),
          ),
        },
        {
          path:'mp-a92/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-ll/mp-a92'),
          ),
        },
        {
          path:'mp-a78/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-ll/mp-a78'),
          ),
        },
        {
          path:'mp-a114/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-ll/mp-a114'),
          ),
        },
        {
          path:'mp-a94/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-ll/mp-a94'),
          ),
        },
        {
          path:'mp-a116/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-ll/mp-a116'),
          ),
        },
        {
          path:'mp-a115/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-ll/mp-a115'),
          ),
        },
        {
          path:'mp-a96/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-ll/mp-a96'),
          ),
        },
        {
          path:'mp-a90/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-ll/mp-a90'),
          ),
        },
        {
          path:'mp-a72/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-ll/mp-a72'),
          ),
        },
        {
          path:'mp-a51/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-ll/mp-a51'),
          ),
        },
        {
          path:'mp-a31/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-ll/mp-a31'),
          ),
        },
        {
          path:'mp-a105/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-ll/mp-a105'),
          ),
        },
        {
          path:'mp-a93/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-ll/mp-a93'),
          ),
        },
        {
          path:'mp-a28/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-ll/mp-a28'),
          ),
        },
        {
          path:'mp-a30/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-ll/mp-a30'),
          ),
        },
        {
          path:'mp-a111/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-ll/mp-a111'),
          ),
        },
        {
          path:'mp-a113/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-ll/mp-a113'),
          ),
        },

      ]
    },
    {
      path: 'wx-pay-al/*',
      element:React.lazy(
          () => import( '@/pages/wx-pay-al'),
      ),
      children: [
        {
          path:'sph-c90/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-al/sph-c90'),
          ),
        },
        {
          path:'sph-c89/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-al/sph-c89'),
          ),
        },
        {
          path:'sph-c88/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-al/sph-c88'),
          ),
        },
        {
          path:'sph-c87/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-al/sph-c87'),
          ),
        },
        {
          path:'sph-c70/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-al/sph-c70'),
          ),
        },
        {
          path:'sph-c63/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-al/sph-c63'),
          ),
        },
        {
          path:'sph-c62/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-al/sph-c62'),
          ),
        },
        {
          path:'sph-c61/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-al/sph-c61'),
          ),
        },
        {
          path:'sph-c60/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-al/sph-c60'),
          ),
        },
        {
          path:'sph-c58/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-al/sph-c58'),
          ),
        },
        {
          path:'hwkf-H01/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-al/hwkf-H01'),
          ),
        },
      ]
    },
    {
      path: 'wx-pay-l/*',
      element:React.lazy(
        () => import( '@/pages/wx-pay-l'),
      ),
      children: [
        {
          path:'360pc-Cp01/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-l/360pc-Cp01'),
          ),
        },
        {
          path:'hwkf-H01/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-l/hwkf-H01'),
          ),
        },
        {
          path:'sph-c38/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-l/sph-c38'),
          ),
        },
        {
          path:'ttkf-Dp03/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-l/ttkf-Dp03'),
          ),
        },
        {
          path:'sph-c34/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-pay-l/sph-c34'),
          ),
        },
      ]
    },
    {
      path:'wx-stock-all/*',
      element:React.lazy(
          ()=>import( '@/pages/wx-stock-all'),
      ),
      children: [
        {
          path:'gdtkf-t01/:pageType',
          element:React.lazy(
              ()=>import( '@/pages/wx-stock-all/gdtkf-t01')
          )
        },
        {
          path:'ttkf-D01/:pageType',
          element:React.lazy(
              ()=>import( '@/pages/wx-stock-all/ttkf-D01')
          )
        },
        {
          path:'bdkf-B01/:pageType',
          element:React.lazy(
              ()=>import( '@/pages/wx-stock-all/bdkf-B01')
          )
        },
        {
          path:'ttkf-H01/:pageType',
          element:React.lazy(
              ()=>import( '@/pages/wx-stock-all/ttkf-H01')
          )
        },
        {
          path:'gdtkf-H01/:pageType',
          element:React.lazy(
              ()=>import( '@/pages/wx-stock-all/gdtkf-H01')
          )
        },
        {
          path:'bdkf-H01/:pageType',
          element:React.lazy(
              ()=>import( '@/pages/wx-stock-all/bdkf-H01')
          )
        },
        {
          path:'sph-c22/:pageType',
          element:React.lazy(
              ()=>import( '@/pages/wx-stock-all/sph-c22')
          )
        },
        {
          path:'sph-c83/:pageType',
          element:React.lazy(
              ()=>import( '@/pages/wx-stock-all/sph-c83')
          )
        },
        {
          path:'hwkf-H01/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-stock-all/hwkf-H01'),
          ),
        },
        {
          path:'hwkf-H02/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-stock-all/hwkf-H02'),
          ),
        },
        {
          path:'vvkf-A01/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-stock-all/vvkf-A01'),
          ),
        },
        {
          path:'vvkf-H01/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-stock-all/vvkf-H01'),
          ),
        },
      ]
    },
    {
      path:'wx-stock-w/*',
      element:React.lazy(
          ()=>import( '@/pages/wx-stock-w'),
      ),
      children: [
        {
          path:'sph-c22/:pageType',
          element:React.lazy(
              ()=>import( '@/pages/wx-stock-w/sph-c22')
          )
        },
        {
          path:'sph-c84/:pageType',
          element:React.lazy(
              ()=>import( '@/pages/wx-stock-w/sph-c84')
          )
        },
        ,{
          path:'hwkf-H01/:pageType',
          element:React.lazy(
              () => import( '@/pages/wx-stock-w/hwkf-H01'),
          ),
        },
      ]
    },
    {
      path: 'wxj-l/*',
      element:React.lazy(
        () => import( '@/pages/wxj-l'),
      ),
      children: [
        {
          path:'hwkf-H01/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-l/hwkf-H01'),
          ),
        },
        {
          path:'ttkf-D14/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-l/ttkf-D14'),
          ),
        },
        {
          path:'ttkf-D13/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-l/ttkf-D13'),
          ),
        },
        {
          path:'ttkf-a01/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-l/ttkf-a01'),
          ),
        },
      ]
    },
    {
      path: 'wxj-al/*',
      element:React.lazy(
          () => import( '@/pages/wxj-al'),
      ),
      children: [
        {
          path:'ttkf-D18/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-al/ttkf-D18'),
          ),
        },
        {
          path:'ttkf-D19/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-al/ttkf-D19'),
          ),
        },
        {
          path:'bdkf-B09/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-al/bdkf-B09'),
          ),
        },
          {
              path:'gdtkf-t05/:pageType',
              element:React.lazy(
                  () => import( '@/pages/wxj-al/gdtkf-t05'),
              ),
          },
        {
          path:'hwkf-H01/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-al/hwkf-H01'),
          ),
        },
        {
          path:'ttkf-D17/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-al/ttkf-D17'),
          ),
        },
      ]
    },
    {
      path: 'wxj-p-al/*',
      element:React.lazy(
          () => import( '@/pages/wxj-p-al'),
      ),
      children: [
        {
          path:'bdkf-Bp18/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-al/bdkf-Bp18'),
          ),
        },
        {
          path:'360kf-Cp01/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-al/360kf-Cp01'),
          ),
        },
        {
          path:'bdkf-Bp17/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-al/bdkf-Bp17'),
          ),
        },
        {
          path:'bdkf-Bp16/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-al/bdkf-Bp16'),
          ),
        },
        {
          path:'bdkf-Bp15/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-al/bdkf-Bp15'),
          ),
        },
        {
          path:'bdkf-Bp14/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-al/bdkf-Bp14'),
          ),
        },
        {
          path:'bdkf-Bp13/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-al/bdkf-Bp13'),
          ),
        },
        {
          path:'bdkf-Bp08/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-al/bdkf-Bp08'),
          ),
        },
        {
          path:'bdkf-Bp07/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-al/bdkf-Bp07'),
          ),
        },
      ]
    },

    {
      path: 'wxj-p-l/*',
      element:React.lazy(
        () => import( '@/pages/wxj-p-l'),
      ),
      children: [
        {
          path:'360pc-Cp01/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-l/360pc-Cp01'),
          ),
        },
        {
          path:'ttkf-Dp53/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-l/ttkf-Dp53'),
          ),
        },
        {
          path:'ttkf-Dp52/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-l/ttkf-Dp52'),
          ),
        },
        {
          path:'ttkf-Dp51/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-l/ttkf-Dp51'),
          ),
        },
        {
          path:'ttkf-Dp50/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-l/ttkf-Dp50'),
          ),
        },
        {
          path:'ttkf-Dp48/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-l/ttkf-Dp48'),
          ),
        },
        {
          path:'ttkf-Dp43/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-l/ttkf-Dp43'),
          ),
        },
        {
          path:'ttkf-Dp41/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-l/ttkf-Dp41'),
          ),
        },
        {
          path:'ttkf-Dp36/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-l/ttkf-Dp36'),
          ),
        },
        {
          path:'bdskf-bds01/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-l/bdskf-bds01'),
          ),
        },
        {
          path:'360skf-a01/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-l/360skf-a01'),
          ),
        },
        {
          path:'360pc-a01/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-l/360pc-a01'),
          ),
        },
        {
          path:'bdpc-03/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-l/bdpc-03'),
          ),
        },
        {
          path:'bdkf-Bp06/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-l/bdkf-Bp06'),
          ),
        },
        {
          path:'gdtkf-tp34/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-l/gdtkf-tp34'),
          ),
        },
        {
          path:'gdtkf-tp33/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-l/gdtkf-tp33'),
          ),
        },
        {
          path:'gdtkf-tp32/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-l/gdtkf-tp32'),
          ),
        },
        {
          path:'gdtkf-tp31/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-l/gdtkf-tp31'),
          ),
        },
        {
          path:'gdtkf-tp30/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-l/gdtkf-tp30'),
          ),
        },
        {
          path:'gdtkf-tp28/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-l/gdtkf-tp28'),
          ),
        },
        {
          path:'gdtkf-tp26/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-l/gdtkf-tp26'),
          ),
        },
          {
              path:'gdtkf-tp24/:pageType',
              element:React.lazy(
                  () => import( '@/pages/wxj-p-l/gdtkf-tp24'),
              ),
          },
        {
          path:'hwkf-H01/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-l/hwkf-H01'),
          ),
        },
        {
          path:'ttkf-Dp35/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-l/ttkf-Dp35'),
          ),
        },
        {
          path:'ttkf-Dp34/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-l/ttkf-Dp34'),
          ),
        },
        {
          path:'ttkf-Dp29/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-l/ttkf-Dp29'),
          ),
        },
        {
          path:'gdtkf-tp20/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-l/gdtkf-tp20'),
          ),
        },
        {
          path:'ttkf-Dp28/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-l/ttkf-Dp28'),
          ),
        },
        {
          path:'ttkf-Dp27/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-l/ttkf-Dp27'),
          ),
        },
        {
          path:'gdtkf-tp16/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-l/gdtkf-tp16'),
          ),
        },
        {
          path:'ttkf-Dp26/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-l/ttkf-Dp26'),
          ),
        },
        {
          path:'ttkf-Dp25/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-l/ttkf-Dp25'),
          ),
        },
        {
          path:'gdtkf-tp15/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-l/gdtkf-tp15'),
          ),
        },
        {
          path:'ttkf-Dp01/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-l/ttkf-Dp01'),
          ),
        },
        {
          path:'ttkf-Dp23/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-l/ttkf-Dp23'),
          ),
        },
        {
          path:'ttkf-Dp24/:pageType',
          element:React.lazy(
              () => import( '@/pages/wxj-p-l/ttkf-Dp24'),
          ),
        },
      ]
    },
    {
      path:'h-l/*',
      element:React.lazy(
          () => import( '@/pages/h-l'),
      ),
      children:[
        {
          path:'jcemob-a01',
          element:React.lazy(
              () => import ( '@/pages/h-l/jcemob-a01'),
          ),
        },
        {
          path:'dxl-a02',
          element:React.lazy(
              () => import( '@/pages/h-l/dxl-a02'),
          ),
        },
        {
          path:'dxl-a01',
          element:React.lazy(
              () => import( '@/pages/h-l/dxl-a01'),
          ),
        },
        {
          path:'bdl-a04',
          element:React.lazy(
              () => import( '@/pages/h-l/bdl-a04'),
          ),
        },
        {
          path:'bdl-a03',
          element:React.lazy(
              () => import( '@/pages/h-l/bdl-a03'),
          ),
        },
        {
          path:'bdl-a02',
          element:React.lazy(
              () => import( '@/pages/h-l/bdl-a02'),
          ),
        },
        {
          path:'bdl-a01',
          element:React.lazy(
              () => import( '@/pages/h-l/bdl-a01'),
          ),
        },
        {
          path:'ttl-a04',
          element:React.lazy(
              () => import( '@/pages/h-l/ttl-a04'),
          ),
        },
        {
          path:'ttl-a03',
          element:React.lazy(
              () => import( '@/pages/h-l/ttl-a03'),
          ),
        },
        {
          path:'ttl-a02',
          element:React.lazy(
              () => import( '@/pages/h-l/ttl-a02'),
          ),
        },
        {
          path:'ttl-a01',
          element:React.lazy(
              () => import( '@/pages/h-l/ttl-a01'),
          ),
        },
        {
          path:'gdtl-a32',
          element:React.lazy(
              () => import( '@/pages/h-l/gdtl-a32'),
          ),
        },
        {
          path:'gdtl-a01',
          element:React.lazy(
              () => import( '@/pages/h-l/gdtl-a01'),
          ),
        }
      ]
    },
    {
      path:'h-pay-l/*',
      element:React.lazy(
          () => import( '@/pages/h-pay-l'),
      ),
      children:[
        {
          path:'ttpl-D129/:pageType',
          element:React.lazy(
              () => import( '@/pages/h-pay-l/ttpl-D129'),
          ),
        },
        {
          path:'ttpl-a01/:pageType',
          element:React.lazy(
              () => import( '@/pages/h-pay-l/ttpl-a01'),
          ),
        },
      ]
    },
    {
      path:'h-pay-K/*',
      element:React.lazy(
          () => import( '@/pages/h-pay-k'),
      ),
      children:[
        {
          path:'ttkf-a01/:pageType',
          element:React.lazy(
              () => import( '@/pages/h-pay-k/ttkf-a01'),
          ),
        },
        {
          path:'ttkf-D138/:pageType',
          element:React.lazy(
              () => import( '@/pages/h-pay-k/ttkf-D138'),
          ),
        },
        {
          path:'ttkf-D139/:pageType',
          element:React.lazy(
              () => import( '@/pages/h-pay-k/ttkf-D139'),
          ),
        },
      ]
    },
  ],
};