// @ts-ignore
/* eslint-disable */
import request from '../../utils/request';

/** 广告页绑定手机号 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /public/ad/bind-mobile */
export async function publicAdBindMobileUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.publicAdBindMobileUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultVoid>('/public/ad/bind-mobile', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 按标签获取广告位列表 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /public/advert */
export async function publicAdvertUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.publicAdvertUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultListAdvertItem>('/public/advert', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 官网获取投顾老师列表 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /public/analyst/gw/list */
export async function publicAnalystGwListUsingGET(options?: { [key: string]: any }) {
  return request<API.BaseResultListSsAnalystInfo>('/public/analyst/gw/list', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 获取直播场次卡片 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /public/app/scene/card */
export async function publicAppSceneCardUsingGET(options?: { [key: string]: any }) {
  return request<API.BaseResultListSceneCardResp>('/public/app/scene/card', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 获取上周和当周直播场次 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /public/app/scene/week/list */
export async function publicAppSceneWeekListUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.publicAppSceneWeekListUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultListSceneResp>('/public/app/scene/week/list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** APP更新提醒 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /public/app/update/remind */
export async function publicAppUpdateRemindUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.publicAppUpdateRemindUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultAppUpdateRemindResp>('/public/app/update/remind', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取文章详情 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /public/articleInfo */
export async function publicArticleInfoUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.publicArticleInfoUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultArticleInfoResp>('/public/articleInfo', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 每日股评 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /public/articleList */
export async function publicArticleListUsingPOST(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.publicArticleListUsingPOSTParams,
  options?: { [key: string]: any },
) {
  return request<API.PageResultListArticleInfoResp>('/public/articleList', {
    method: 'POST',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 保存广告行为数据 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /public/convert/create */
export async function publicConvertCreateUsingPOST(
  body: API.CreateConvertInfoReq,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultstring>('/public/convert/create', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 缩短参数 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /public/convert/exchange */
export async function publicConvertExchangeUsingPOST(
  body: string,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultstring>('/public/convert/exchange', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** web调用回传数据 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /public/convert/web */
export async function publicConvertWebUsingPOST(
  body: API.WebConvertReq,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultstring>('/public/convert/web', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 根据广告页地址获取获客链接 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /public/customer_acquisition/get_link_url */
export async function publicCustomerAcquisitionGetLinkUrlUsingPOST(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.publicCustomerAcquisitionGetLinkUrlUsingPOSTParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultstring>('/public/customer_acquisition/get_link_url', {
    method: 'POST',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 创建或更新设备 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /public/device */
export async function publicDeviceUsingPOST(
  body: API.CreateOrUpdateDeviceReq,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultstring>('/public/device', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 查询官网客服 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /public/findGwServerInfo */
export async function publicFindGwServerInfoUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.publicFindGwServerInfoUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultGwServerInfoResp>('/public/findGwServerInfo', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 生成回访问卷pdf # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /public/generateFeedbackPdf */
export async function publicGenerateFeedbackPdfUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.publicGenerateFeedbackPdfUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<any>('/public/generateFeedbackPdf', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 生成退款确认书pdf # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /public/generateOrderRefundPdf */
export async function publicGenerateOrderRefundPdfUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.publicGenerateOrderRefundPdfUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<any>('/public/generateOrderRefundPdf', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取新闻频道列表 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /public/getNewsChannels */
export async function publicGetNewsChannelsUsingGET(options?: { [key: string]: any }) {
  return request<API.BaseResultListNewsChannelResp>('/public/getNewsChannels', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 获取指定新闻详情 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /public/getNewsInfo */
export async function publicGetNewsInfoUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.publicGetNewsInfoUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultNewsContentResp>('/public/getNewsInfo', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取指定频道最新新闻列表 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /public/getNewsList */
export async function publicGetNewsListUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.publicGetNewsListUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.PageResultListNewsListResp>('/public/getNewsList', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取官网可展示的员工列表 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /public/gw/staff */
export async function publicGwStaffUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.publicGwStaffUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.PageResultListDingDingStaffItem>('/public/gw/staff', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** kf # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /public/kf/url */
export async function publicKfUrlUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.publicKfUrlUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultNpWwxKfSceneRelationResp>('/public/kf/url', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** (无需登录)获取合同显示的订单信息 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /public/orders/contract/${param0} */
export async function publicOrdersContractOrderNumberUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.publicOrdersContractOrderNumberUsingGETParams,
  options?: { [key: string]: any },
) {
  const { orderNumber: param0, ...queryParams } = params;
  return request<API.BaseResultOrderContractDetail>(`/public/orders/contract/${param0}`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** (无需登录)通过订单号获取订单详情 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /public/orders/number/${param0} */
export async function publicOrdersNumberOrderNumberUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.publicOrdersNumberOrderNumberUsingGETParams,
  options?: { [key: string]: any },
) {
  const { orderNumber: param0, ...queryParams } = params;
  return request<API.BaseResultOrderDetail>(`/public/orders/number/${param0}`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** (无需登录)创建可支付的子订单 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /public/orders/sub */
export async function publicOrdersSubUsingPOST(
  body: API.CreatePayableSubOrderReq,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultCreatePayableSubOrderResp>('/public/orders/sub', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** (无需登录)通过子订单号获取子订单信息 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /public/orders/sub/number/${param0} */
export async function publicOrdersSubNumberSubOrderNumberUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.publicOrdersSubNumberSubOrderNumberUsingGETParams,
  options?: { [key: string]: any },
) {
  const { subOrderNumber: param0, ...queryParams } = params;
  return request<API.BaseResultSubOrderInfo>(`/public/orders/sub/number/${param0}`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** (无需登录)通过子订单流水号获取订单详情 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /public/orders/sub/serialNumber */
export async function publicOrdersSubSerialNumberUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.publicOrdersSubSerialNumberUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultOrderDetail>('/public/orders/sub/serialNumber', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** (无需登录)获取子订单支付状态 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /public/orders/sub/status */
export async function publicOrdersSubStatusUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.publicOrdersSubStatusUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultSubOrderStatusResp>('/public/orders/sub/status', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 退款单pdf信息填充 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /public/refund/pdf/fill */
export async function publicRefundPdfFillUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.publicRefundPdfFillUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultMapstring>('/public/refund/pdf/fill', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** (无需登录)通过skuId获取sku详情 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /public/sku/${param0}/detail */
export async function publicSkuSkuIdDetailUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.publicSkuSkuIdDetailUsingGETParams,
  options?: { [key: string]: any },
) {
  const { skuId: param0, ...queryParams } = params;
  return request<API.BaseResultSkuDetailResp>(`/public/sku/${param0}/detail`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 获取选股宝股票池 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /public/stock/pool */
export async function publicStockPoolUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.publicStockPoolUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultListStockListResp>('/public/stock/pool', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取验证码智能验证配置 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /public/verify-intelligent/captcha/config */
export async function publicVerifyIntelligentCaptchaConfigUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.publicVerifyIntelligentCaptchaConfigUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultVerifyIntelligentCaptchaConfigResp>(
    '/public/verify-intelligent/captcha/config',
    {
      method: 'GET',
      params: {
        ...params,
      },
      ...(options || {}),
    },
  );
}

/** 获取播放信息 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /public/video/play */
export async function publicVideoPlayUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.publicVideoPlayUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultGetPlayInfoResponse>('/public/video/play', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取服务包开通用户数量 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /public/vip/subscriptionCount/${param0} */
export async function publicVipSubscriptionCountLevelUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.publicVipSubscriptionCountLevelUsingGETParams,
  options?: { [key: string]: any },
) {
  const { level: param0, ...queryParams } = params;
  return request<API.BaseResultVipSubscriptionCountResp>(
    `/public/vip/subscriptionCount/${param0}`,
    {
      method: 'GET',
      params: { ...queryParams },
      ...(options || {}),
    },
  );
}

/** 拉二维码 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /public/workwx/contactway/info */
export async function publicWorkwxContactwayInfoUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.publicWorkwxContactwayInfoUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultWwxWayResp>('/public/workwx/contactway/info', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 生成小程序schema # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /public/wx/ma/schema */
export async function publicWxMaSchemaUsingPOST(
  body: API.CreateMaSchemaReq,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultstring>('/public/wx/ma/schema', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}
