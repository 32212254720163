// @ts-ignore
/* eslint-disable */
import request from '../../utils/request';

/** 获取合同投顾老师 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /content/contract-advisor/info */
export async function contentContractAdvisorInfoUsingGET(options?: { [key: string]: any }) {
  return request<API.BaseResultstring>('/content/contract-advisor/info', {
    method: 'GET',
    ...(options || {}),
  });
}
