// @ts-ignore
/* eslint-disable */
import request from '../../utils/request';

/** 案例股票池频道列表 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /stock/case/channel/list */
export async function stockCaseChannelListUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.stockCaseChannelListUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultListStockCaseChannelItem>('/stock/case/channel/list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 案例股票池频道列表(PC端) # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /stock/case/channel/pc */
export async function stockCaseChannelPcUsingGET(options?: { [key: string]: any }) {
  return request<API.BaseResultListPcStockCaseChannelItem>('/stock/case/channel/pc', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 获取聊天室案例老师 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
# **接口权限** 
 接口需要用户对应服务的权限，curl请求示例： 
  ```
  curl -X GET --header accept: application/json http://localhost:8107/stock/case/chat/case/analyst?roomId=roomId
  ```
 GET /stock/case/chat/case/analyst */
export async function stockCaseChatCaseAnalystUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.stockCaseChatCaseAnalystUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultListCaseAnalystResp>('/stock/case/chat/case/analyst', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 聊天室案例操作列表(全部) # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /stock/case/chat/deal/all */
export async function stockCaseChatDealAllUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.stockCaseChatDealAllUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.PageResultListCaseNewsResp>('/stock/case/chat/deal/all', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 聊天室案例操作列表 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
# **接口权限** 
 接口需要用户对应服务的权限，curl请求示例： 
  ```
  curl -X GET --header accept: application/json http://localhost:8107/stock/case/chat/deal/list?roomId=roomId
  ```
 GET /stock/case/chat/deal/list */
export async function stockCaseChatDealListUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.stockCaseChatDealListUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.PageResultListCaseNewsResp>('/stock/case/chat/deal/list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 聊天室案例列表 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
# **接口权限** 
 接口需要用户对应服务的权限，curl请求示例： 
  ```
  curl -X GET --header accept: application/json http://localhost:8107/stock/case/chat/list?roomId=roomId
  ```
 GET /stock/case/chat/list */
export async function stockCaseChatListUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.stockCaseChatListUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.PageResultListCaseNewsResp>('/stock/case/chat/list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 聊天室案例-我的关注 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
# **接口权限** 
 接口需要用户对应服务的权限，curl请求示例： 
  ```
  curl -X GET --header accept: application/json http://localhost:8107/stock/case/chat/mySub?roomId=roomId
  ```
 GET /stock/case/chat/mySub */
export async function stockCaseChatMySubUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.stockCaseChatMySubUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.PageResultListCaseNewsResp>('/stock/case/chat/mySub', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取用户案例关注剩余次数 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /stock/case/chat/subCount */
export async function stockCaseChatSubCountUsingGET(options?: { [key: string]: any }) {
  return request<API.BaseResultSubStockCaseCountResp>('/stock/case/chat/subCount', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 获取股票池案例详情 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /stock/case/info */
export async function stockCaseInfoUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.stockCaseInfoUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultStockCaseInfoResp>('/stock/case/info', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 案例股票池-案例列表(1.0) # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /stock/case/l1/list */
export async function stockCaseL1ListUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.stockCaseL1ListUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.PageResultListStockCaseDetailResp>('/stock/case/l1/list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 案例股票池-案例列表(1.0) # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /stock/case/l1/list/pool */
export async function stockCaseL1ListPoolUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.stockCaseL1ListPoolUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.PageResultListStockCaseDetailResp>('/stock/case/l1/list/pool', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取最后一条案例操作 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /stock/case/lastCaseDeal */
export async function stockCaseLastCaseDealUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.stockCaseLastCaseDealUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultStockCaseDealResp>('/stock/case/lastCaseDeal', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 案例股票池-案例列表 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /stock/case/list */
export async function stockCaseListUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.stockCaseListUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.PageResultListStockCaseDetailResp>('/stock/case/list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取股票池案例180天内最高涨幅 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /stock/case/maxRatio */
export async function stockCaseMaxRatioUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.stockCaseMaxRatioUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultStockCaseProfitResp>('/stock/case/maxRatio', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 案例股票最新动态 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /stock/case/news */
export async function stockCaseNewsUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.stockCaseNewsUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.PageResultListCaseNewsResp>('/stock/case/news', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 一档栏目公告(股票池) # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /stock/case/notice */
export async function stockCaseNoticeUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.stockCaseNoticeUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.PageResultListStockCaseNoticeResp>('/stock/case/notice', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 一档栏目公告(股票池) # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /stock/case/notice/pool */
export async function stockCaseNoticePoolUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.stockCaseNoticePoolUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.PageResultListStockCaseNoticeResp>('/stock/case/notice/pool', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 案例股票池列表(PC端) # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /stock/case/pc */
export async function stockCasePcUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.stockCasePcUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.PageResultListPcStockCaseDetailResp>('/stock/case/pc', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 案例股票池列表 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /stock/case/pool/list */
export async function stockCasePoolListUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.stockCasePoolListUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultListStockCaseResp>('/stock/case/pool/list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 上榜股票 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /stock/case/rank */
export async function stockCaseRankUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.stockCaseRankUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.PageResultListStockCaseDetailResp>('/stock/case/rank', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 上榜股票(股票池) # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /stock/case/rank/pool */
export async function stockCaseRankPoolUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.stockCaseRankPoolUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.PageResultListStockCaseDetailResp>('/stock/case/rank/pool', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 案例股票池信号列表(PC端) # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /stock/case/signal */
export async function stockCaseSignalUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.stockCaseSignalUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultListPcStockCaseSignalResp>('/stock/case/signal', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 案例股票池-案例列表(1.0) # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /stock/case/stockDetail */
export async function stockCaseStockDetailUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.stockCaseStockDetailUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultStockCaseDetailResp>('/stock/case/stockDetail', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 案例股票池-案例列表(1.0) # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /stock/case/stockDetail/pool */
export async function stockCaseStockDetailPoolUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.stockCaseStockDetailPoolUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultStockCaseDetailResp>('/stock/case/stockDetail/pool', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 策略组持仓列表（l3） # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /stock/case/strategy */
export async function stockCaseStrategyUsingGET(options?: { [key: string]: any }) {
  return request<API.BaseResultStrategyResp>('/stock/case/strategy', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 策略组概览明细(去掉行情调用) # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /stock/case/strategy/detail */
export async function stockCaseStrategyDetailUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.stockCaseStrategyDetailUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultListStrategyStockCaseResp>('/stock/case/strategy/detail', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 策略组持仓操作列表 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /stock/case/strategy/stockDealList */
export async function stockCaseStrategyStockDealListUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.stockCaseStrategyStockDealListUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultListStrategyStockDealResp>('/stock/case/strategy/stockDealList', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 案例股票池-关注/取关 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /stock/case/sub/editSubInfo */
export async function stockCaseSubEditSubInfoUsingPOST(
  body: API.EditStockCaseSubInfoReq,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultstring>('/stock/case/sub/editSubInfo', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}
