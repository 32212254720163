// @ts-ignore
/* eslint-disable */
import request from '../../utils/request';

/** 策略增值申请详情 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /strategy-apply/info */
export async function strategyApplyInfoUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.strategyApplyInfoUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultStrategyApplyInfoResp>('/strategy-apply/info', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 策略增值包签字 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /strategy-apply/sign */
export async function strategyApplySignUsingPOST(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.strategyApplySignUsingPOSTParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultESignStatusResp>('/strategy-apply/sign', {
    method: 'POST',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 查询订单签字状态 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /strategy-apply/sign/status */
export async function strategyApplySignStatusUsingPOST(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.strategyApplySignStatusUsingPOSTParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultESignStatusResp>('/strategy-apply/sign/status', {
    method: 'POST',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 策略增值包获取签字url # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /strategy-apply/sign/url */
export async function strategyApplySignUrlUsingPOST(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.strategyApplySignUrlUsingPOSTParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultstring>('/strategy-apply/sign/url', {
    method: 'POST',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
