// @ts-ignore
/* eslint-disable */
import request from '../../utils/request';

/** 生成验证码 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /sms/generate_code */
export async function smsGenerateCodeUsingGET(options?: { [key: string]: any }) {
  return request<API.BaseResultImageCode>('/sms/generate_code', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 发送短信验证码（阿里智能验证码版） # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /sms/ic-verification-code */
export async function smsIcVerificationCodeUsingPOST(
  body: API.VerificationCodeReq,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultSendSmsResp>('/sms/ic-verification-code', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 发送语音验证码（阿里智能验证码版） # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /sms/ic-voice-code */
export async function smsIcVoiceCodeUsingPOST(
  body: API.VerificationCodeReq,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultSendSmsResp>('/sms/ic-voice-code', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 发送短信验证码 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /sms/verification_code */
export async function smsVerificationCodeUsingPOST(
  body: API.ImageCodeReq,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultSendSmsResp>('/sms/verification_code', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 发送短信验证码 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /sms/verification_code_new */
export async function smsVerificationCodeNewUsingPOST(
  body: API.ImageCodeReq,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultSendSmsResp>('/sms/verification_code_new', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 发送语音验证码 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /sms/voice_code */
export async function smsVoiceCodeUsingPOST(
  body: API.ImageCodeReq,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultSendSmsResp>('/sms/voice_code', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}
