// @ts-ignore
/* eslint-disable */
import request from '../../utils/request';

/** Apple授权登录 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /app/auth/apple/login */
export async function appAuthAppleLoginUsingPOST(
  body: API.AppleLoginReq,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultAppleLoginResp>('/app/auth/apple/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** Apple一键注册 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /app/auth/apple/oneKeyRegister */
export async function appAuthAppleOneKeyRegisterUsingPOST(
  body: API.TokenValidateReq,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultAppleLoginResp>('/app/auth/apple/oneKeyRegister', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** Apple注册 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /app/auth/apple/register */
export async function appAuthAppleRegisterUsingPOST(
  body: API.AppleRegisterReq,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultAppleLoginResp>('/app/auth/apple/register', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** app设备互踢检查 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /app/auth/check-login-status */
export async function appAuthCheckLoginStatusUsingPOST(
  body: API.AppInfo,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultCheckLoginStatusResp>('/app/auth/check-login-status', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** App用户登录 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /app/auth/login */
export async function appAuthLoginUsingPOST(
  body: API.AppLoginReq,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultAppLoginResp>('/app/auth/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** App一键登录 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /app/auth/oneKeyLogin */
export async function appAuthOneKeyLoginUsingPOST(
  body: API.TokenValidateReq,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultGenerateTokenResp>('/app/auth/oneKeyLogin', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 通过RefreshSession登录(已废弃-替换为auth/session-login) # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /app/auth/session-login */
export async function appAuthSessionLoginUsingPOST(
  body: API.SessionLoginReq,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultAccessToken>('/app/auth/session-login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 刷新AccessToken # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /app/auth/token/refresh */
export async function appAuthTokenRefreshUsingPOST(
  body: API.RefreshTokenReq,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultAccessToken>('/app/auth/token/refresh', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** App用户注销 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /app/auth/user/cancel */
export async function appAuthUserCancelUsingPOST(
  body: API.AppCancelReq,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultVoid>('/app/auth/user/cancel', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** App微信授权后一键登录 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /app/auth/wx/oneKeyRegister */
export async function appAuthWxOneKeyRegisterUsingPOST(
  body: API.AppWxRegisterReq,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultAppWxLoginResp>('/app/auth/wx/oneKeyRegister', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** App微信登录 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /app/auth/wxLogin */
export async function appAuthWxLoginUsingPOST(
  body: API.AppWxLoginReq,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultAppWxLoginResp>('/app/auth/wxLogin', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** App微信注册 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /app/auth/wxRegister */
export async function appAuthWxRegisterUsingPOST(
  body: API.AppWxRegisterReq,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultAppWxLoginResp>('/app/auth/wxRegister', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}
