// @ts-ignore
/* eslint-disable */
import request from '../../utils/request';

/** 获取投资圈内容主列表 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /public/team */
export async function publicTeamUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.publicTeamUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultListContentTeamItem>('/public/team', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取内容主详情 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /public/team/${param0} */
export async function publicTeamNumberUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.publicTeamNumberUsingGETParams,
  options?: { [key: string]: any },
) {
  const { number: param0, ...queryParams } = params;
  return request<API.BaseResultContentTeamItem>(`/public/team/${param0}`, {
    method: 'GET',
    params: {
      ...queryParams,
    },
    ...(options || {}),
  });
}

/** 按内容主获取每日股评 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /public/team/articles/daily */
export async function publicTeamArticlesDailyUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.publicTeamArticlesDailyUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.PageResultListTeamDailyArticleItem>('/public/team/articles/daily', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 按内容主获取文章 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /public/team/articles/daily-by-number */
export async function publicTeamArticlesDailyByNumberUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.publicTeamArticlesDailyByNumberUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.PageResultListTeamDailyArticleItem>('/public/team/articles/daily-by-number', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取每日股评文章详情 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /public/team/articles/daily/${param0} */
export async function publicTeamArticlesDailyNumberUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.publicTeamArticlesDailyNumberUsingGETParams,
  options?: { [key: string]: any },
) {
  const { number: param0, ...queryParams } = params;
  return request<API.BaseResultTeamDailyArticleDetail>(`/public/team/articles/daily/${param0}`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 获取每日股评文章评论 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /public/team/articles/daily/comments */
export async function publicTeamArticlesDailyCommentsUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.publicTeamArticlesDailyCommentsUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.PageResultListArticleCommentItem>('/public/team/articles/daily/comments', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 按内容主获取最新每日股评 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /public/team/articles/daily/latest */
export async function publicTeamArticlesDailyLatestUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.publicTeamArticlesDailyLatestUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultListTeamDailyArticleItem>('/public/team/articles/daily/latest', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取观大盘配置 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /public/team/banner */
export async function publicTeamBannerUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.publicTeamBannerUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultTeamBannerItem>('/public/team/banner', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 内容主视频评论列表 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /public/team/commentList */
export async function publicTeamCommentListUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.publicTeamCommentListUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.PageResultListShortVideoCommentListResp>('/public/team/commentList', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取投资圈首页 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /public/team/index */
export async function publicTeamIndexUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.publicTeamIndexUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultListContentTeamFrontItem>('/public/team/index', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 内容主视频信息 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /public/team/info */
export async function publicTeamInfoUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.publicTeamInfoUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultTeamShortVideoResp>('/public/team/info', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 按内容主获取解盘消息列表 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /public/team/messages */
export async function publicTeamMessagesUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.publicTeamMessagesUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.PageResultListTeamStreamMessageItem>('/public/team/messages', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取内容主解盘消息详情 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /public/team/messages/${param0} */
export async function publicTeamMessagesMessageIdUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.publicTeamMessagesMessageIdUsingGETParams,
  options?: { [key: string]: any },
) {
  const { messageId: param0, ...queryParams } = params;
  return request<API.BaseResultTeamStreamMessageDetail>(`/public/team/messages/${param0}`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 按内容主获取最新解盘消息列表 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /public/team/messages/latest */
export async function publicTeamMessagesLatestUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.publicTeamMessagesLatestUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultListTeamStreamMessageItem>('/public/team/messages/latest', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取内容主短视频列表 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /public/team/video/list */
export async function publicTeamVideoListUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.publicTeamVideoListUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.PageResultListSsShortVideoResp>('/public/team/video/list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
