// @ts-ignore
/* eslint-disable */
import request from '../../utils/request';

/** 获取App券商列表 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /app/config/broker */
export async function appConfigBrokerUsingGET(options?: { [key: string]: any }) {
  return request<API.BaseResultBrokerConfigResp>('/app/config/broker', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 获取App最新的下载链接 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /app/config/download */
export async function appConfigDownloadUsingGET(options?: { [key: string]: any }) {
  return request<API.BaseResultAppDownloadResp>('/app/config/download', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 获取客户服务url # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /app/config/getCustomerServiceUrl */
export async function appConfigGetCustomerServiceUrlUsingGET(options?: { [key: string]: any }) {
  return request<API.BaseResultAppConfigResp>('/app/config/getCustomerServiceUrl', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 获取App最新的版本信息 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /app/config/getVersion */
export async function appConfigGetVersionUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.appConfigGetVersionUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultAppConfigResp>('/app/config/getVersion', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取首页推荐指标 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /app/config/index-list */
export async function appConfigIndexListUsingGET(options?: { [key: string]: any }) {
  return request<API.BaseResultListAppIndexConfigResp>('/app/config/index-list', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 获取App的功能导航栏 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /app/config/navbar */
export async function appConfigNavbarUsingGET(options?: { [key: string]: any }) {
  return request<API.BaseResultListNavbarConfig>('/app/config/navbar', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 查看App是否显示登录 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /app/config/show-login */
export async function appConfigShowLoginUsingGET(options?: { [key: string]: any }) {
  return request<API.BaseResultAppShowLoginResp>('/app/config/show-login', {
    method: 'GET',
    ...(options || {}),
  });
}
