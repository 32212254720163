import commonSetting from "@/config/commonSetting";
const pathToRegexp = require('path-to-regexp')
import api from "@/services/api/index";
import commonHelper from "@/utils/commonHelper";

import {Toast} from "antd-mobile";



const getInitialState = async ( dispatch, scope) => {
    let accessToken = localStorage.getItem('accessToken');
    if (accessToken && accessToken.split('.')[1])  {
      return true
    } else {
      await commonHelper.redirectWxAuthorizationUrl(encodeURIComponent(window.location.href))
      return
    }
}
const fetchUserInfo = async () => {
  try {
    const resp = await api.userController.usersMeUsingGET();
    let userInfo = resp.data;
    return userInfo
  } catch (error) {
    console.log(error)
    return null
  }
};
const getWxAuthorizationUrl = async (redirectUri, scope) => {
  let params =  {
    category: 'promotion',
    redirectUri: redirectUri,
    companyType: commonSetting.COMPANY_TYPE,
    scope: scope || 'snsapi_base',
  }
  debugger
  const resp = await api.wxOauth2Controller.wxOauth2UrlRedirectUsingGET(params);
  if(resp.code == 0){
    console.log('获取授权链接：',resp.data)
      return resp.data
  }else {
      console.log('授权链接获取失败:',resp.code)
      return null
  }
}
const n2oLink = async (origin,  path) => {
  location.href = origin + 'n2o?path=' +  encodeURIComponent(path) + '&accountType=' + commonSetting.ACCOUNT_TYPE + '&openId=' + localStorage.getItem('openId')+ '&wxId=' + localStorage.getItem('wxId')
}
const redirectWxAuthorizationUrl = async (path, scope)=>{
 // debugger
  let url = await getWxAuthorizationUrl(path, scope);
  // debugger
  if(url){
    location.replace(url)
  }else {
    // Toast.show({
    //   icon: 'fail',
    //   content: '未知错误',
    // })
  }
}

const redirectProduct = async (domain,) => {
  let url = domain
  location.replace(url)
}
// 静态资源路劲
const fileUrlFilter = (url) => {
  if (url) {
    if (url.indexOf('http://') === 0 || url.indexOf('https://') === 0) {
      return url;
    } else {
      return 'https://tj-file-oss.cf69.com/' + url;
    }
  } else {
    return url;
  }
}
const urlFilter = (url) => {
  if (!url) {
    return ''
  }
  if (url.indexOf('http://') === 0 || url.indexOf('https://') === 0) {
      return url;
  } else {
    return 'https://' + url
  }
}

const hasKeyInArr = (arr, key, val) =>{
  for (const arrKey in arr) {
    if(arr[arrKey][key] === val){
      return arr[arrKey]
    }
  }
  return false
}

const parseAlign = (columns, direction)=>{
  return columns.map(item=>({...item, align:direction}))
}

/**
 *
 * @param str 模糊匹配数组里的字符串
 * @param arr
 */
export function searchPath(arr, str) {
  let matchSuccess = false
  for (let i = 0; i < arr.length; i++) {
    let aa = pathToRegexp(arr[i], [], {end: true}).test(str)
    if(aa){
      matchSuccess = true
      break
    }
  }
  // 最后返回结果
  return matchSuccess
}
//微信环境付款
const wxEnvPay = (payParam, cb) => {
  console.log("------------------------payParam:", payParam)
  console.log("------------------------window.wechatSDK:", window.wechatSDK)
  if(!window.wechatSDK){
    return
  }
  window.wechatSDK.callWechatApi('chooseWXPay', {
    timestamp: payParam?.payInfo?.timeStamp,
    nonceStr: payParam?.payInfo?.nonceStr,
    package: payParam?.payInfo?.package,
    signType: payParam?.payInfo?.signType,
    paySign: payParam?.payInfo?.paySign,
    success: async function (res) {
      console.error("payInfosuccess_success", res);
      if (res.errMsg === 'chooseWXPay:ok') {
        cb()
      } else {
        Toast.show({ content: '未获取到有效付款信息！' })
      }
    },
    fail: function (res) {
      console.error(res);
      Toast.show({ content: '未获取到有效付款信息！' })
      //location.reload();
    },
    cancel: function (res) {//取消支付，取消、异常都是进入该回调
      console.error('取消支付，取消、异常都是进入该回调',res);
      //location.reload();
    },
    complete: function (res) {
      console.error(res);
      //location.reload();
    },
  });
}
const reqUploadTrackEventCNZZ = function (category, action, label, linkUrl, $event) {

  window._czc && window._czc.push(['_trackEvent', category, action, label])
}

export function getUserAgent(){
  const userAgentMap = new Map([
    ["isAndroid", false],
    ["isIphone", false],
    ["isIpad", false],
    ["isWechat", false],
    ["isAli", false],
    ["isPhone", false],
    ["isSafari", false]
  ])
  let userAgent = navigator.userAgent.toLowerCase()
  if (userAgent.match(/android/ig)) {
    userAgentMap.set("isAndroid", true)
  }
  if (userAgent.match(/iphone|ipod|IOS/ig)) {
    userAgentMap.set("isIphone", true)
  }
  if (userAgent.match(/ipad/ig)) {
    userAgentMap.set("isIpad", true)
  }
  if (userAgent.match(/MicroMessenger/ig)) {
    console.log("isWechat----------------------------")
    userAgentMap.set("isWechat", true)
  }
  if (userAgent.match(/AlipayClient/ig)) {
    userAgentMap.set("isAli", true)
  }
  if (/(iPhone|iPad|iPod|IOS|Android)/i.test(userAgent)) {
    userAgentMap.set("isPhone", true)
  }
  if (/^((?!chrome|android).)*safari/i.test(userAgent)) {
    userAgentMap.set("isSafari", true)
  }
  return userAgentMap
}
function marketIdToMarketName(marketId) {
  switch (marketId) {
    case 0:
      return 'SH';
    case 1:
      return 'SH';
    case 7:
      return 'SH';
    case 1000:
      return 'SZ';
    case 1001:
      return 'SZ';
    case 1008:
      return 'SZ';
  }
}
const customerTrackUpload = async (pageId, category, action, label,) => {
  let params = {
    pageId,  // 页面id
    appId: 'ad',  //应用id
    category,  // 事件
    action,  // 行为
    label,  // 具体描述
    url: window.location.href
  }

  try {
    await api.customerTrackController.userCustomerTrackUploadEnvenUsingPOST(params)
  } catch (error) {
    console.log(error)
  }
}

const longTimePressUpload = (class_name,path_name,dzid)=>{
  let timer = null
  const _dom = document.querySelector(class_name)
  if (!_dom) return
  _dom.addEventListener('touchstart', function () {
    timer = setTimeout(function () {
      // 如果长按达到350ms
      __bl?.sum(`${path_name}_${dzid}`)
    }, 350)
  })
  _dom.addEventListener('touchmove', e => {
    // 手指有移动就重置 长按的状态
    clearTimeout(timer)
    timer = null
  })
  _dom.addEventListener('touchend', function () {
    clearTimeout(timer)
    timer = null
  })
}

const expiresLocalStorage =  {
  setItem: function (key, value, time) {
    const payload = Number.isFinite(time)
        ? {
          __data: value,
          __expiresTime: Date.now() + time,
        }
        : value;
    localStorage.setItem(key, JSON.stringify(payload));
  },
  getItem: function (key) {
    const value = Storage.prototype.getItem.call(localStorage, key);
    try {
      const jsonVal = JSON.parse(value);
      if (jsonVal.__expiresTime) {
        return jsonVal.__expiresTime >= Date.now()
            ? jsonVal.__data
            : void 0;
      }
      return value;
    } catch (error) {
      return value;
    }
  },
  removeItem: function (key) {
    localStorage.removeItem(key);
  },
}
export default {
  n2oLink,
  parseAlign,
  fetchUserInfo,
  redirectProduct,
  getWxAuthorizationUrl,
  redirectWxAuthorizationUrl,
  getInitialState,
  reqUploadTrackEventCNZZ,
  getUserAgent,
  expiresLocalStorage,
  hasKeyInArr,
  wxEnvPay,
  fileUrlFilter,
  urlFilter,
  marketIdToMarketName,
  customerTrackUpload,
  longTimePressUpload
}
