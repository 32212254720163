// @ts-ignore
/* eslint-disable */
import request from '../../utils/request';

/** 当前交易状态 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /strategy-stock/current-trade-status */
export async function strategyStockCurrentTradeStatusUsingGET(options?: { [key: string]: any }) {
  return request<API.BaseResultTradeStatusResp>('/strategy-stock/current-trade-status', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 策略股票池详情 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /strategy-stock/pool/detail */
export async function strategyStockPoolDetailUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.strategyStockPoolDetailUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultListStrategyStockPoolDetailResp>('/strategy-stock/pool/detail', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 策略股票池战绩 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /strategy-stock/pool/excellent-record */
export async function strategyStockPoolExcellentRecordUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.strategyStockPoolExcellentRecordUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultListStrategyStockPoolDetailResp>(
    '/strategy-stock/pool/excellent-record',
    {
      method: 'GET',
      params: {
        ...params,
      },
      ...(options || {}),
    },
  );
}

/** 策略股票池统计 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /strategy-stock/pool/frequency-statistics */
export async function strategyStockPoolFrequencyStatisticsUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.strategyStockPoolFrequencyStatisticsUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultFrequencyStatisticsResp>(
    '/strategy-stock/pool/frequency-statistics',
    {
      method: 'GET',
      params: {
        ...params,
      },
      ...(options || {}),
    },
  );
}

/** 策略股票池走势 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /strategy-stock/pool/fund-trend */
export async function strategyStockPoolFundTrendUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.strategyStockPoolFundTrendUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultListStrategyStockPoolFundTrendResp>(
    '/strategy-stock/pool/fund-trend',
    {
      method: 'GET',
      params: {
        ...params,
      },
      ...(options || {}),
    },
  );
}

/** 策略股票池信息 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /strategy-stock/pool/info */
export async function strategyStockPoolInfoUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.strategyStockPoolInfoUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultStrategyStockPoolResp>('/strategy-stock/pool/info', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 策略股票池列表 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /strategy-stock/pool/list */
export async function strategyStockPoolListUsingGET(options?: { [key: string]: any }) {
  return request<API.BaseResultListStrategyStockPoolResp>('/strategy-stock/pool/list', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 获取量化策略股票池推送配置信息 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /strategy-stock/pool/notice */
export async function strategyStockPoolNoticeUsingGET(options?: { [key: string]: any }) {
  return request<API.BaseResultboolean>('/strategy-stock/pool/notice', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 设置量化策略股票池推送配置信息 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /strategy-stock/pool/notice/set */
export async function strategyStockPoolNoticeSetUsingPOST(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.strategyStockPoolNoticeSetUsingPOSTParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultVoid>('/strategy-stock/pool/notice/set', {
    method: 'POST',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取策略股票预选池 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /strategy-stock/pool/pre-selection */
export async function strategyStockPoolPreSelectionUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.strategyStockPoolPreSelectionUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultStrategyStockPreSelectionPoolDetailResp>(
    '/strategy-stock/pool/pre-selection',
    {
      method: 'GET',
      params: {
        ...params,
      },
      ...(options || {}),
    },
  );
}

/** 订阅 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /strategy-stock/pool/sub */
export async function strategyStockPoolSubUsingPOST(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.strategyStockPoolSubUsingPOSTParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultboolean>('/strategy-stock/pool/sub', {
    method: 'POST',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
