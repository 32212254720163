// @ts-ignore
/* eslint-disable */
import request from '../../utils/request';

/** 获取用户的认证信息 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /users/certification */
export async function usersCertificationUsingGET(options?: { [key: string]: any }) {
  return request<API.BaseResultUserCertification>('/users/certification', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 获取手机号修改次数 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /users/change-mobile-check */
export async function usersChangeMobileCheckUsingGET(options?: { [key: string]: any }) {
  return request<API.BaseResultVoid>('/users/change-mobile-check', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 更换手机号 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /users/changeMobile */
export async function usersChangeMobileUsingPOST(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.usersChangeMobileUsingPOSTParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultboolean>('/users/changeMobile', {
    method: 'POST',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取用户的优惠券 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /users/coupons */
export async function usersCouponsUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.usersCouponsUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultUserCouponResp>('/users/coupons', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 重新认证 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /users/evaluationId/redo */
export async function usersEvaluationIdRedoUsingPOST(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.usersEvaluationIdRedoUsingPOSTParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultstring>('/users/evaluationId/redo', {
    method: 'POST',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取免费的栏目列表 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /users/free/subscriptions/columns */
export async function usersFreeSubscriptionsColumnsUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.usersFreeSubscriptionsColumnsUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultListPermissionInfo>('/users/free/subscriptions/columns', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取服务热线 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /users/hotline */
export async function usersHotlineUsingGET(options?: { [key: string]: any }) {
  return request<API.BaseResultServiceHotlineResp>('/users/hotline', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 三要素认证 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /users/id_card */
export async function usersIdCardUsingPOST(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.usersIdCardUsingPOSTParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultboolean>('/users/id_card', {
    method: 'POST',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取用户的测评信息 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /users/idCard */
export async function usersIdCardUsingGET(options?: { [key: string]: any }) {
  return request<API.BaseResultboolean>('/users/idCard', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 获取当前用户信息 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /users/me */
export async function usersMeUsingGET(options?: { [key: string]: any }) {
  return request<API.BaseResultUserDetailResp>('/users/me', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 非三要素认证 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /users/other */
export async function usersOtherUsingPOST(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.usersOtherUsingPOSTParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultboolean>('/users/other', {
    method: 'POST',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取当前用户完整信息 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /users/profile */
export async function usersProfileUsingGET(options?: { [key: string]: any }) {
  return request<API.BaseResultUserProfileResp>('/users/profile', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 获取销售信息 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /users/sales/${param0} */
export async function usersSalesNumberUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.usersSalesNumberUsingGETParams,
  options?: { [key: string]: any },
) {
  const { number: param0, ...queryParams } = params;
  return request<API.BaseResultSalesInfo>(`/users/sales/${param0}`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 获取用户已开通的服务详情 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /users/vip/packages/${param0}/services */
export async function usersVipPackagesNumberServicesUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.usersVipPackagesNumberServicesUsingGETParams,
  options?: { [key: string]: any },
) {
  const { number: param0, ...queryParams } = params;
  return request<API.BaseResultUserVipPackageInfo>(`/users/vip/packages/${param0}/services`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 获取用户已开通的聊天室服务列表 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /users/vip/packages/service/chat-room/list */
export async function usersVipPackagesServiceChatRoomListUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.usersVipPackagesServiceChatRoomListUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultListVipPackageServiceItem>(
    '/users/vip/packages/service/chat-room/list',
    {
      method: 'GET',
      params: {
        ...params,
      },
      ...(options || {}),
    },
  );
}

/** 获取用户已开通的服务 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /users/vip/subscriptions */
export async function usersVipSubscriptionsUsingGET(options?: { [key: string]: any }) {
  return request<API.BaseResultListVipSubscriptionInfo>('/users/vip/subscriptions', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 获取当前用户开通的栏目列表 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /users/vip/subscriptions/columns */
export async function usersVipSubscriptionsColumnsUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.usersVipSubscriptionsColumnsUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultListPermissionInfo>('/users/vip/subscriptions/columns', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取当前用户开通的指标列表(PC端) # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /users/vip/subscriptions/pc/columns */
export async function usersVipSubscriptionsPcColumnsUsingGET(options?: { [key: string]: any }) {
  return request<API.BaseResultListPcPermissionInfo>('/users/vip/subscriptions/pc/columns', {
    method: 'GET',
    ...(options || {}),
  });
}
