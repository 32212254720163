// @ts-ignore
/* eslint-disable */
import request from '../../utils/request';

/** 用户手机号登录 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /auth/mobile/login */
export async function authMobileLoginUsingPOST(
  body: API.MobileLoginReq,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultMobileLoginResp>('/auth/mobile/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 通过RefreshSession登录 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /auth/session-login */
export async function authSessionLoginUsingPOST(
  body: API.SessionLoginReq,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultLoginSessionResp>('/auth/session-login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 刷新AccessToken # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /auth/token/refresh */
export async function authTokenRefreshUsingPOST(
  body: API.SessionLoginReq,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultAccessToken>('/auth/token/refresh', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 微信公众号授权登录 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /auth/wx-oa-login */
export async function authWxOaLoginUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.authWxOaLoginUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultWxOaLoginResp>('/auth/wx-oa-login', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取微信公众号授权链接 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /auth/wx-oa-redirect */
export async function authWxOaRedirectUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.authWxOaRedirectUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultstring>('/auth/wx-oa-redirect', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
