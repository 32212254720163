// @ts-ignore
/* eslint-disable */
import request from '../../utils/request';

/** 激活兑换码 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /dd-order/coupon/activate */
export async function ddOrderCouponActivateUsingPOST(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.ddOrderCouponActivateUsingPOSTParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultVoid>('/dd-order/coupon/activate', {
    method: 'POST',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取兑换码详情 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /dd-order/coupon/info */
export async function ddOrderCouponInfoUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.ddOrderCouponInfoUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultCouponInfoResp>('/dd-order/coupon/info', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 兑换记录 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /dd-order/coupon/my-list */
export async function ddOrderCouponMyListUsingGET(options?: { [key: string]: any }) {
  return request<API.BaseResultListCouponInfoResp>('/dd-order/coupon/my-list', {
    method: 'GET',
    ...(options || {}),
  });
}
