// @ts-ignore
/* eslint-disable */
import request from '../../utils/request';

/** 获取文件url # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /promotion-resource/file-url */
export async function promotionResourceFileUrlUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.promotionResourceFileUrlUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultURL>('/promotion-resource/file-url', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 客户领取文件夹url # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /promotion-resource/link-url */
export async function promotionResourceLinkUrlUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.promotionResourceLinkUrlUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultstring>('/promotion-resource/link-url', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取文件列表 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /promotion-resource/list */
export async function promotionResourceListUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.promotionResourceListUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultReceivePromotionResourceResp>('/promotion-resource/list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
